// all common imports;

@mixin AppList($app-logo-border-color, $table-bordered-border) {
  .appLogo {
    height: 20px;
    width: 20px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50px;
      object-fit: cover;
      border: 0.5px solid $app-logo-border-color;
    }
  }
  .installbtn {
    button {
      font-size: 10px;
      padding: 5px 10px;
      border: none;
    }
  }
  
  .table-responsive > .table-bordered{
    border: 1px solid $table-bordered-border !important;
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include AppList($app-logo-border-color, $table-bordered-border);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include AppList($app-logo-border-color, $table-bordered-border);
}

/* *********    ******************************* */
// all common imports;

@mixin CalculatedField($label-color,$label-detail-webkit-scrollbar-track-background, $label-detail-webkit-scrollbar-track-background-thumb, $label-detail-webkit-scrollbar-track-background-scrollbar-thumb) {

  .details-label{
    color: $label-color;
  }

  .details {
    height: 100%;
    max-height: 100%;
    width: 100%;
    position: relative;
    border-radius: 5px;
  }

  .details::-webkit-scrollbar {
    height: 6px;
    width: 5px;
    transition: 0.3s;
  }

  .details::-webkit-scrollbar-track {
    background-color: $label-detail-webkit-scrollbar-track-background;
    transition: 0.3s;
  }

  .details::-webkit-scrollbar-thumb {
    background-color: $label-detail-webkit-scrollbar-track-background-thumb;
    transition: 0.3s;
    border-radius: 10px;

  }

  .details:hover::-webkit-scrollbar-thumb {
    background-color: $label-detail-webkit-scrollbar-track-background-scrollbar-thumb;
  }


}

html.default {
  @import "../../../../../styles/themes/theme-default.scss";
  @import "../../../../../styles/theme-color-variables.scss";
  @include CalculatedField($blue, $webkit-scrollbar-background-light, $webkit-scrollbar-background-medium, $webkit-scrollbar-background-dark);
}

html.dark {
  @import "../../../../../styles/themes/theme-dark.scss";
  @import "../../../../../styles/theme-color-variables.scss";
  @include CalculatedField($blue, $webkit-scrollbar-background-light, $webkit-scrollbar-background-medium, $webkit-scrollbar-background-dark);
}
@mixin TableSummary($border-color-light, $border-color-blue){
    .summaryLabel{
        padding: 5px;
        text-wrap: nowrap;
    }
    .summaryValue{
        padding: 5px;
        text-wrap: nowrap;
    }
    .summaryPanel{
        border-left: 3px solid $border-color-blue;
        margin-top: 3px;
        margin-bottom: 3px;
    }
    .sectionField{
        width: 33.33%;
    }
    .borderClass{
        border-right: 1px solid $border-color-light;
    }
    .sectionFieldData{
        width: 100%;
    }  
    .overallContent{
        width: 100%;
    }
    @media screen and (max-width: 768px){
        .sectionField{
            width: 100%;
        }
    }
} 

html.default{
    @import "../../../styles/themes/theme-default.scss";
    @import "../../../styles/theme-color-variables.scss";
    @include TableSummary($border-color-light, $border-color-blue)
}

html.dark{
    @import "../../../styles/themes/theme-dark.scss";
    @import "../../../styles/theme-color-variables.scss";
    @include TableSummary($border-color-light, $border-color-blue)
}
// all common imports;

@mixin CreateApp($advance-node-height-color, $create-app-accordion-button) {
  .icon {
    float: right !important ;
  }
  .message {
    font-weight: bolder;
  }
  .node-menu-Manipulation {
    display: none;
  }

  .vp_height {
    h2 {
      font-size: 25px;
      font-weight: 500;
      margin: 5px 0px;
    }
  }

  .advance_node_height {
    border: 0.5px solid $advance-node-height-color;
    padding: 10px;
    border-radius: 0px;
    margin: 10px 0px;
  }

  .create-app .accordion-button:not(.collapsed) {
    background-color: $create-app-accordion-button !important;
  }

  .create-app .accordion-button:focus {
    box-shadow: none !important;
  }
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include CreateApp($advance-node-height-color, $create-app-accordion-button);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include CreateApp($advance-node-height-color, $create-app-accordion-button);
}

// Import your styles at the top level
@import "../../../styles/themes/theme-default.scss";
@import "../../../styles/themes/theme-dark.scss";
@import "../../../styles/theme-color-variables.scss";

#root { 
    $sliderTrackColor : $primary;
    $sliderShifterColor : $primary;
}

.horizontal-slider {
    width: 100%;
    height: 6px;
    background: $white;
    border-radius: 3px;
    margin-top: 10px;
    position: relative;
}

.thumb {
    height: 18px;
    width: 18px;
    background-color: var(--sliderShifterColor);
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .track {
    background: var(--sliderTrackColor);
    height: 6px;
    border-radius: 3px;
  }

  .slider-container{
    width: 95%;
    margin: 5px auto;
    text-align: center;
  }

  .slider-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: $dark;
  }
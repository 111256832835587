// all common imports;

@mixin AddDetails($app-label-required) {
  .addField {
    .text-lowerCase {
      text-transform: lowercase;
    }
    .required:after {
      content: " *";
      color: $app-label-required;
      position: absolute;
    }
    #htmlCode1 > .ql-container > .ql-tooltip {
      z-index: 1;
      top: 33px !important;
      left: -1px !important;
    }
    #htmlCode1 > .ql-container > .ql-ql-flip {
      z-index: 1;
      top: 55px !important;
      left: -1px !important;
    }
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include AddDetails($app-label-required);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include AddDetails($app-label-required);
}
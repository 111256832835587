// all common imports;

@mixin InstalledApp($application-search-border) {
  .application_table {
    table.table-bordered.appsTable td {
      white-space: pre-wrap;
    }
    .action-icons {
      text-align: center;
      vertical-align: middle;
      padding: 0px;
    }
    .searchbar_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input-wrapper {
        position: relative;
        font-size: 14px;
        margin-right: 10px;
        i {
          position: absolute;
          top: 8px;
          left: 15px;
          z-index: 1;
        }
  
        .application_search {
          padding: 4px;
          width: 100%;
          border: 0.75px solid $application-search-border;
          position: relative;
          padding-left: 35px;
        }
      }
    }
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include InstalledApp($application-search-border);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include InstalledApp($application-search-border);
}
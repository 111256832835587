@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap/scss/_functions.scss";
@import "../../node_modules/bootstrap/scss/_variables.scss";
@import "./variable.scss";
@import "../../node_modules/react-toastify/dist/ReactToastify.css";
@import "../../node_modules/react-confirm-alert/src/react-confirm-alert.css";

$offcanvas-horizontal-width: 70%;
@media (max-width: 768px) {
  .offcanvas {
    --bs-offcanvas-width: 100%;
  }
}

html.default {
  @import "./themes/theme-default.scss";
  @import "./theme-color-variables.scss";
  @import "./index.scss";
}
html.dark {
  @import "./themes/theme-dark.scss";
  @import "./theme-color-variables.scss";
  @import "./index.scss";
}

@media (max-width: 1200px) {
  .graph {
    zoom: 76%;
    height: inherit;
  }
  .item {
    display: block;
  }
}
@media (min-width: 480px) {
  .top_nav .navbar-right li {
    position: relative;
  }
  .item {
    display: block;
  }
  .nav_menu {
    position: static;
  }
  .item {
    display: block;
  }
}
@media (max-width: 414px) {
  .item {
    display: block;
  }
}
@media (max-width: 400px) {
  .item {
    display: block;
  }
}
@media (min-width: 360px) and (max-width: 812px) {
  .graph {
    zoom: 71%;
    height: inherit;
  }
}

@media (max-width: 768px) {
  .x_panel {
    padding: 0px;
  }
  .login_main img {
    display: none;
  }
  .backimg {
    height: auto !important;
  }
  .login_main_content {
    height: auto !important;
    padding-left: 0px !important;
    border-left: 0px solid #34495ee6 !important;
  }
  .footer_title {
    padding-left: 15px;
    p {
      float: none !important;
    }
  }
  .footer_title {
    height: auto;
  }
  .backimg {
    height: auto !important;
  }
  .login_main .img-logo {
    margin-bottom: 0px;
    width: 30%;
  }
  .login_main .col-md-12 button {
    float: none;
  }

  .react_ui .form-group.field.field-string,
  .react_ui .form-group.field.field-number,
  .react_ui .form-group.field.field-object .form-group.field.field-object,
  .react_ui .form-group.field.field-array {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .text-sm-14px {
    font-size: 14px;
  }

  .display-sm-none {
    display: none;
  }
  .sm-tableLayoutInitial {
    table-layout: initial !important;
  }

  .sm-width200 {
    width: 200px;
    display: block;
  }
  .modal-open .modal {
    z-index: 9999;
  }
  // ends
}

@media (max-width: 577px) {
  .dataTables_paginate {
    float: left;
  }
}

@media (max-width: 577px) {
  .dynamic_pagination {
    justify-content: start;
  }
}

@media (max-width: 768px) {
  .tenant_main_content {
    border-left: none !important;
  }
}

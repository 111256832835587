// all common imports;

@mixin DatasetTable($dataset-table-border ,$dataset-table-search-border,$table-dynamic-pagination-color) {
  .dataset_table {
    .table_head:nth-child(6) {
      width: 8%;
    }
  
    table.table-bordered.dataTable td {
      white-space: pre-wrap;
      border-bottom-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .table-responsive > .table-bordered {
      border: 1px solid $dataset-table-border !important;
    }
    .action-icons {
      vertical-align: middle;
      padding: 0px;
    }
    .searchbar_wrapper {
      display: flex;
      align-items: center;
      .input-wrapper {
        position: relative;
        font-size: 14px;
        margin-right: 10px;
        svg {
          position: absolute;
          top: 8px;
          left: 15px;
          z-index: 1;
        }
  
        .dataset_search {
          padding: 4px;
          border: 0.75px solid $dataset-table-search-border;
          position: relative;
          width: 100%;
          padding-left: 35px;
        }
      }
    }
  
    #page-numbers {
      list-style: none;
      display: flex;
    }
  
    #page-numbers > li {
      margin-right: 0.3em;
      color: $table-dynamic-pagination-color;
      user-select: none;
      cursor: pointer;
    }
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include DatasetTable($dataset-table-border ,$dataset-table-search-border,$table-dynamic-pagination-color);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include DatasetTable($dataset-table-border ,$dataset-table-search-border,$table-dynamic-pagination-color);
}
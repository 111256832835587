// all common imports;

@mixin CheckboxRJSF() {
  .disableCheckbox {
    opacity: 0.5;
  }

}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include CheckboxRJSF();
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include CheckboxRJSF();
}
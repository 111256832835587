/* CARD-category */
//@import "../../../../styles/theme-color-variables.scss";

@mixin CategoryCardListing($card-category-box-shadow,
  $card-category-border,$listing-badge-background,$listing-badge-color,
  $card-category-title-color
) {
  .cards {
    display: flex;
    flex-wrap: wrap;
  }
  
  .card-category {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    box-shadow: 0px 1px 4px 1px rgba($card-category-box-shadow, 0.25);
    transition: all 0.2s;
    border-top: 5px solid $card-category-border;
    cursor: pointer;
    margin-right: 1%;
    .listing-badge {
      height: 30px;
      width: 30px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $listing-badge-background;
      color: $listing-badge-color;
      font-weight: bold;
    }
  }
  
  .card-category:hover {
    transform: scale(1.01);
    border: none;
  }
  
  .card-category__title {
    color: $card-category-title-color;
  }
  
  .card-category__link:hover::after {
    width: 100%;
  }
  
  /* CARD-category BACKGROUNDS */
  
  @media only screen and (max-width: 480px) {
    .responsive-heading {
      margin-bottom: 10px;
      h5 {
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px !important;
      }
    }
  }
  
  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include CategoryCardListing($card-category-box-shadow,$card-category-border,$listing-badge-background,$listing-badge-color,$card-category-title-color);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include CategoryCardListing($card-category-box-shadow,$card-category-border,$listing-badge-background,$listing-badge-color,$card-category-title-color);
}

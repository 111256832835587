// all common imports;

@mixin RootCategory($selected-category-background,$selected-category-border,$selected-category-webkit-box-shadow,$folder-icon-color,$disabled-color) {
  .selectedCategory {
    background-color: $selected-category-background;
    border: $selected-category-border solid 1px;
    -webkit-box-shadow: 2px 2px 6px -3px rgba($selected-category-webkit-box-shadow, 0.33);
    -moz-box-shadow: 2px 2px 6px -3px rgba($selected-category-webkit-box-shadow, 0.33);
    box-shadow: 2px 2px 6px -3px rgba($selected-category-webkit-box-shadow, 0.33);
    font-weight: 600;
    min-width: 100px;
  }
  
  .rootCategory {
    flex-grow: 1;
  }
  
  .addCategory_btn {
    font-size: 18px;
  }
  
  .folderIconColor {
    color: $folder-icon-color;
  }
  
  .openCategoryLine {
    position: relative;
  }
  
  .openCategoryLine:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 1.5px dashed;
  }
  
  .disableCategory{
    color: $disabled-color;
  }
  
  .selectCategory {
    .ant-select {
      height: 40px;
    }
  
    .ant-select-selector {
      height: 40px !important;
      padding-top: 4px !important;
    }
  }
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include RootCategory($selected-category-background,$selected-category-border,$selected-category-webkit-box-shadow,$folder-icon-color,$disabled-color);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include RootCategory($selected-category-background,$selected-category-border,$selected-category-webkit-box-shadow,$folder-icon-color,$disabled-color);
}
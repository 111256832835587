/* TimeLineView.scss */

@mixin TimeLineView($white,$gray, $font-color, $active-color, $background-color, $border-color,$webkit-scrollbar-background-light,$webkit-scrollbar-background-medium,$webkit-scrollbar-background-dark) {
  .timeline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
    padding: 10px 0;
    border-radius: 5px;
    background-color: $background-color;
    overflow: scroll;
  }
    .timeline-data{
      background-color:$white ;
      padding: 10px;
      position: relative;
      border-radius: 5px;
      &:hover{
        scale: 1.1;
        transition: all .2s ease-in-out ;
      }
    }

    .timeline::-webkit-scrollbar {
      width: 5px;
      background-color: red;
    }
  
    .timeline-data:after {
      content: " ";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -14px;
      border-top: 15px solid $white;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
      border-bottom: none;
    }
  
    .step {
      flex: 1;
      text-align: center;
      position: relative;
      padding: 10px;
      white-space: nowrap;
    }
  
    .timeline-circle {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: $gray;
      margin-top: 15px;
      z-index: 2;
      color: $font-color;
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: 600;
    }
  
    .active-timeline {
      background-color: $active-color;
    }
  
    .timeline-label {
      font-weight: lighter;
      font-size: 12px;
    }

    .timeline-status{
      font-weight: bolder;
      font-size: 12px;
    }
  
    .step:not(:last-child)::after {
      content: '';
      position: absolute;
      bottom:30px;
      left: 100%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 5px;
      background-color: $gray;
      z-index: 1;
    }
  
    .value-change{
      background:$background-color;
      padding:10px;
      border-radius:5px;
    }

    .timeline::-webkit-scrollbar {
      height: 6px;
      width: 0px;
      transition: 0.3s;
    }
    
    .timeline::-webkit-scrollbar-track {
      background-color: $webkit-scrollbar-background-light;
      transition: 0.3s;
    }
    
    .timeline::-webkit-scrollbar-thumb {
      background-color: $webkit-scrollbar-background-medium;
      transition: 0.3s;
    }
    .timeline:hover::-webkit-scrollbar-thumb {
      background-color: $webkit-scrollbar-background-dark;
    }
  
  }


html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include TimeLineView($white, $gray, $font-color-white, $background-color-blue, $background-color-light, $border-color-dark,$webkit-scrollbar-background-light,$webkit-scrollbar-background-medium,$webkit-scrollbar-background-dark);
}

html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include TimeLineView($white, $gray, $font-color-black, $background-color-blue, $background-color-light , $border-color-white,$webkit-scrollbar-background-light,$webkit-scrollbar-background-medium,$webkit-scrollbar-background-dark);
}
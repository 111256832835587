// all common imports;

@mixin Categories($ecommerce-category-border,$ecommerce-category-background,$ecommerce-category-shadow,$listing-badge-background,$listing-badge-color,$share-wrapper-background,$share-wrapper-color) {
  .category-card-design {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 0px solid $ecommerce-category-border;
    border-radius: 8px;
    padding: 10px 10px;
    background-color: $ecommerce-category-background;
    .img-wrapper {
      margin-right: 10px;
      img {
        object-fit: cover;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        box-shadow: 0px 0px 3px 0px $ecommerce-category-shadow;
      }
    }
  
    .content-wrapper {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .badge-wrapper span {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $listing-badge-background;
      color: $listing-badge-color;
      font-weight: 700;
      font-size: 12px;
      line-height: 1;
    }
  }
  .share-wrapper {
    cursor: pointer;
    background: $share-wrapper-background;
    font-size: 12px;
    padding: 8px 15px;
    border-radius: 5px;
    font-weight: bold;
    color: $share-wrapper-color;
    position: relative;
  }
  
  .breadcrumbContainer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
  }
  
  .categoryDescription {
    font-size: 14px;
    text-align: justify;
  }
  
  #react-select-3-placeholder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include Categories($ecommerce-category-border,$ecommerce-category-background,$ecommerce-category-shadow,$listing-badge-background,$listing-badge-color,$share-wrapper-background,$share-wrapper-color);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include Categories($ecommerce-category-border,$ecommerce-category-background,$ecommerce-category-shadow,$listing-badge-background,$listing-badge-color,$share-wrapper-background,$share-wrapper-color);
}
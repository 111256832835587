@import "../../../node_modules/bootstrap/scss/_functions.scss";

// Color system
// reference: https://themesbrand.com/veltrix/layouts/ui-colors.html
// color-variables
$white: #fff !default;
$white-transparent: #ffffff0f !default;
$white-partial-transparent: #ffffff96 !default;
$black: #000 !default;
$black-transparent: #00000033 !default;
$black-transparent1: #0000001a !default;
$black-transparent2: #00000040 !default;
$gray: #9e9e9e !default;
$custom-light-gray: #f6f6f6 !default;
//$blue: #2196f3 !default;
$blue: #626ed4 !default;
$indigo: #3f51b5 !default;
$purple: #9c27b0 !default;
$pink: #e91e63 !default;
//$red: #ef5350 !default;
$red: #ec4561 !default;
$orange: #ff9800 !default;
//$yellow: #ffeb3b !default;
$yellow: #f8b425 !default;
//$green: #66bb6a !default;
$green: #02a499 !default;
$light-green: #8bc34a !default;
$teal: #009688 !default;
//$cyan: #00bcd4 !default;
$cyan: #38a4f8 !default;
//$blue-gray: #607d8b !default;
$blue-gray: #e9ecef !default;
$custom-light-blue-gray: #f6f7fb !default;
$deep-purple: #673ab7 !default;
$light-blue: #03a9f4 !default;
$lime: #cddc39 !default;
$amber: #ffc107 !default;
$deep-orange: #ff5722 !default;
$deep-gray: #333547 !default;
$deep-gray-secondary: #2a3042 !default;

// All different shades of theme colorts
// Shades of Gray
$gray-50: tint-color($gray, 90%) !default;
$gray-100: tint-color($gray, 80%) !default;
$gray-200: tint-color($gray, 60%) !default;
$gray-300: tint-color($gray, 40%) !default;
$gray-400: tint-color($gray, 20%) !default;
$gray-500: $gray !default;
$gray-600: shade-color($gray, 20%) !default;
$gray-700: shade-color($gray, 40%) !default;
$gray-800: shade-color($gray, 60%) !default;
$gray-900: shade-color($gray, 80%) !default;

$blue-50: tint-color($blue, 90%) !default;
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-50: tint-color($indigo, 90%) !default;
$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-50: tint-color($purple, 90%) !default;
$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-50: tint-color($pink, 90%) !default;
$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-50: tint-color($red, 90%) !default;
$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-50: tint-color($orange, 90%) !default;
$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-50: tint-color($yellow, 90%) !default;
$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-50: tint-color($green, 90%) !default;
$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$light-green-50: tint-color($light-green, 80%) !default;
$light-green-100: tint-color($light-green, 80%) !default;
$light-green-200: tint-color($light-green, 60%) !default;
$light-green-300: tint-color($light-green, 40%) !default;
$light-green-400: tint-color($light-green, 20%) !default;
$light-green-500: $light-green !default;
$light-green-600: shade-color($light-green, 20%) !default;
$light-green-700: shade-color($light-green, 40%) !default;
$light-green-800: shade-color($light-green, 60%) !default;
$light-green-900: shade-color($light-green, 80%) !default;

$teal-50: tint-color($teal, 90%) !default;
$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-50: tint-color($cyan, 90%) !default;
$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$blue-gray-50: tint-color($blue-gray, 90%) !default;
$blue-gray-100: tint-color($blue-gray, 80%) !default;
$blue-gray-200: tint-color($blue-gray, 60%) !default;
$blue-gray-300: tint-color($blue-gray, 40%) !default;
$blue-gray-400: tint-color($blue-gray, 20%) !default;
$blue-gray-500: $blue-gray !default;
$blue-gray-600: shade-color($blue-gray, 20%) !default;
$blue-gray-700: shade-color($blue-gray, 40%) !default;
$blue-gray-800: shade-color($blue-gray, 60%) !default;
$blue-gray-900: shade-color($blue-gray, 80%) !default;

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $blue-gray !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: #dee2e6 !default;
$dark: #e9ecef !default;
// scss-docs-end theme-color-variables

// scss-docs-start sass-dark-mode-vars
// scss-docs-start theme-text-dark-variables
$primary-text-emphasis-dark:        tint-color($primary, 40%) !default;
$secondary-text-emphasis-dark:      tint-color($secondary, 40%) !default;
$success-text-emphasis-dark:        tint-color($success, 40%) !default;
$info-text-emphasis-dark:           tint-color($info, 40%) !default;
$warning-text-emphasis-dark:        tint-color($warning, 40%) !default;
$danger-text-emphasis-dark:         tint-color($danger, 40%) !default;
$light-text-emphasis-dark:          $gray-100 !default;
$dark-text-emphasis-dark:           $gray-300 !default;
// scss-docs-end theme-text-dark-variables

// scss-docs-start theme-bg-subtle-dark-variables
$primary-bg-subtle-dark:            shade-color($primary, 80%) !default;
$secondary-bg-subtle-dark:          shade-color($secondary, 80%) !default;
$success-bg-subtle-dark:            shade-color($success, 80%) !default;
$info-bg-subtle-dark:               shade-color($info, 80%) !default;
$warning-bg-subtle-dark:            shade-color($warning, 80%) !default;
$danger-bg-subtle-dark:             shade-color($danger, 80%) !default;
$light-bg-subtle-dark:              $gray-800 !default;
$dark-bg-subtle-dark:               mix($gray-800, $black) !default;
// scss-docs-end theme-bg-subtle-dark-variables

// scss-docs-start theme-border-subtle-dark-variables
$primary-border-subtle-dark:        shade-color($primary, 40%) !default;
$secondary-border-subtle-dark:      shade-color($secondary, 40%) !default;
$success-border-subtle-dark:        shade-color($success, 40%) !default;
$info-border-subtle-dark:           shade-color($info, 40%) !default;
$warning-border-subtle-dark:        shade-color($warning, 40%) !default;
$danger-border-subtle-dark:         shade-color($danger, 40%) !default;
$light-border-subtle-dark:          $gray-700 !default;
$dark-border-subtle-dark:           $gray-800 !default;
// scss-docs-end theme-border-subtle-dark-variables

$body-color-dark:                   $deep-gray !default;
$body-bg-dark:                      $deep-gray !default;
$body-secondary-color-dark:         rgba($body-color-dark, .75) !default;
$body-secondary-bg-dark:            $gray-800 !default;
$body-tertiary-color-dark:          rgba($body-color-dark, .5) !default;
$body-tertiary-bg-dark:             mix($gray-800, $gray-900, 50%) !default;
$body-emphasis-color-dark:          $white !default;
$border-color-dark:                 $deep-gray !default;
$border-color-translucent-dark:     rgba($white, .15) !default;
$headings-color-dark:               inherit !default;
$link-color-dark:                   tint-color($primary, 40%) !default;
$link-hover-color-dark:             shift-color($link-color-dark, 40%) !default;

//
// Forms
//

$form-select-indicator-color-dark:  $body-color-dark !default;
$form-select-indicator-dark:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;

$form-switch-color-dark:            rgba($white, .25) !default;
$form-switch-bg-image-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color-dark}'/></svg>") !default;

// scss-docs-start form-validation-colors-dark
$form-valid-color-dark:             $green-300 !default;
$form-valid-border-color-dark:      $green-300 !default;
$form-invalid-color-dark:           $red-300 !default;
$form-invalid-border-color-dark:    $red-300 !default;
// scss-docs-end form-validation-colors-dark


//
// Accordion
//

$accordion-icon-color-dark:         $primary-text-emphasis-dark !default;
$accordion-icon-active-color-dark:  $primary-text-emphasis-dark !default;

$accordion-button-icon-dark:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon-dark:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color-dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// scss-docs-end sass-dark-mode-vars


// ****************************************
// Application common theme colors
// ****************************************

// reinitializing the gray color again
$deep-gray: #333547;

// Common colors for font, background and border
$font-color-white: tint-color($deep-gray, 80%);
$font-color-black: $white;
$font-color-light-blue: $blue-50;
$font-color-blue: $primary-text-emphasis-dark;
$font-color-red: $danger-text-emphasis-dark;
$font-color-green: $success-text-emphasis-dark;
$font-color-teal: $teal-50;
$font-color-orange: $orange-50;
$font-color-purple: $purple-50;
$font-color-dark-700: $blue-gray-50;
$font-color-light: $white;
$font-color-medium: tint-color($deep-gray, 80%);
$font-color-dark: tint-color($deep-gray, 90%);
$font-color-yellow: $warning-text-emphasis-dark;

$background-color-white: $deep-gray;
$background-color-black: $deep-gray;
$background-color-blue: $blue-500;
$background-color-medium-blue: $blue-500;
$background-color-light-blue: $blue-900;
$background-color-red: $red-800;
$background-color-light-red: $red-600;
$background-color-green: $green-800;
$background-color-teal: $teal-800;
$background-color-orange: $orange-800;
$background-color-purple: $purple-800;
$background-color-dark-700: $blue-gray-700;
$background-color-light: tint-color($deep-gray, 40%);
$background-color-medium: tint-color($deep-gray, 20%);
$background-color-dark: $deep-gray;
$background-color-gray: $gray-900;

$border-color-default: tint-color($deep-gray, 40%);
$border-color-white: tint-color($deep-gray, 30%);
$border-color-black: $deep-gray;
$border-color-light-blue: $blue-300;
$border-color-blue: $blue-700;
$border-color-red: $red-700;
$border-color-green: $green-700;
$border-color-teal: $teal-700;
$border-color-orange: $orange-700;
$border-color-purple: $purple-700;
$border-color-dark-700: $blue-gray-700;
$border-color-light: tint-color($deep-gray, 20%);
$border-color-medium: tint-color($deep-gray, 10%);
$border-color-dark: $deep-gray;
$border-color-yellow: $yellow-700;

$heading-background-white: tint-color($deep-gray, 60%);
$heading-background-black: $deep-gray;
$heading-background-blue: $blue-800;
$heading-background-medium-blue: $blue-500;
$heading-background-light-blue: $blue-900;
$heading-background-red: $red-800;
$heading-background-light-red: $red-600;
$heading-background-green: $green-800;
$heading-background-teal: $teal-800;
$heading-background-orange: $orange-800;
$heading-background-purple: $purple-800;
$heading-background-dark-700: $blue-gray-700;
$heading-background-light: tint-color($deep-gray, 40%);
$heading-background-medium: tint-color($deep-gray, 20%);
$heading-background-dark: $deep-gray;

$heading-color-white: tint-color($deep-gray, 80%);
$heading-color-black: $white;
$heading-color-light-blue: $blue-50;
$heading-color-blue: $primary-text-emphasis-dark;
$heading-color-red: $danger-text-emphasis-dark;
$heading-color-green: $success-text-emphasis-dark;
$heading-color-teal: $teal-50;
$heading-color-orange: $orange-50;
$heading-color-purple: $purple-50;
$heading-color-dark-700: $blue-gray-50;
$heading-color-light: $white;
$heading-color-medium: tint-color($deep-gray, 80%);
$heading-color-dark: tint-color($deep-gray, 90%);
$heading-color-yellow: $warning-text-emphasis-dark;

$shadow-color-blue: $blue-600;
$shadow-color-red: $red-600;
$shadow-color-green: $green-600;
$shadow-color-purple: $purple-600;
$shadow-color-dark-700: $blue-gray-600;
$shadow-color-light: $blue-gray-700;
$shadow-color-medium: $blue-gray-700;
$shadow-color-dark: $blue-gray-700;

$webkit-scrollbar-background-light: $blue-gray-400;
$webkit-scrollbar-background-medium: $blue-gray-700;
$webkit-scrollbar-background-dark: $blue-gray-900;

$no-data-found-color: $font-color-light;

// Body
$body-default-color: $font-color-light;
$body-default-background: $body-bg-dark;
$body-background: $body-bg-dark;
$center-loader-background: rgba($background-color-dark, 0.35);
$background-color: $background-color-dark !default;

// Table
$table-bg-scale: $deep-gray !important;
$table-color: $font-color-light;
$table-bg: $deep-gray !important;

// Buttons
$btn-color: $white;

// Menu
$menu-background-color: $deep-gray-secondary;
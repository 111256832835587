// all common imports;

@mixin Store($store-list-category-border,$store-content-color) {
  .store-img-wrapper {
    height: 30px;
    width: 30px;
    img {
      object-fit: fill;
      height: 100%;
      width: 100%;
    }
  }
  .store-list {
    .store-category {
      border: 1px solid $store-list-category-border;
      .store-category:last-child {
        margin-right: 0px;
      }
      cursor: pointer;
      .img-wrapper {
        height: 145px;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .add-wrapper {
        font-size: 15px;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 2rem;
        }
      }
      p {
        font-size: 16px;
        padding: 10px 0px;
      }
      .store-content {
        p {
          border-top: 1px solid $store-list-category-border;
          font-size: 14px;
          padding: 3px 0px;
          background: $store-list-category-border;
          color: $store-content-color;
        }
      }
    }
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Store($store-list-category-border,$store-content-color);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Store($store-list-category-border,$store-content-color);
}
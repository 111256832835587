// all common imports;

@mixin BigCalendar($calendar-color-dark,$calendar-color-light) {
  .set-calender-height {
    height: 650px !important;
  }
  
  button.rbc-button-link {
    padding: 0px !important;
  }
  .rbc-overlay {
    height: 170px;
    overflow-y: scroll;
    width: 19.5% !important;
  }
  
  .rbc-time-header-cell {
    height: 80px;
  }
  span.rbc-btn-group > button {
    font-weight: bold;
    color: $calendar-color-dark;
  }
  button.rbc-button-link {
    font-size: 0.9rem;
    font-weight: bold;
    color: $calendar-color-dark;
    padding: 5px;
  }
  .rbc-toolbar {
    font-size: 12px;
    width: 90%;
  }
  .rbc-toolbar-label {
    font-size: 20px;
    font-weight: bold;
    color: $calendar-color-dark;
  }
  .rbc-month-header {
    height: 40px;
  }
  .rbc-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $calendar-color-dark;
    color: $calendar-color-light;
    font-size: 14px;
    font-weight: bold;
    .rbc-header > span {
      font-size: 14px;
    }
  }
  .rbc-event {
    border-radius: 0px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 5px;
  }
  .rbc-show-more {
    margin-left: 10px;
  }
  .linkToPageChart {
    position: absolute;
    top: 27px;
    z-index: 4;
    right: 15px;
  }
  
  .dataObj {
    font-size: 14px;
    .task-details {
      height: 100px !important;
    }
  }
  
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    width: 33%;
  }
  
  .rbc-agenda-table > thead > tr {
    display: flex;
  }
  .rbc-event {
    background: $calendar-color-dark;
    color: $calendar-color-light;
    font-weight: bold;
  }
  
  .task-completed > .rbc-event-content {
    text-decoration: line-through;
  }
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include BigCalendar($calendar-color-dark,$calendar-color-light);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include BigCalendar($calendar-color-dark,$calendar-color-light);
}
// all common imports;

@mixin CreateSchedule($toggle-btn-color,$cron-string-text-color,$cron-string-container-header-border) {
  .cron_builder {
    max-width: 100% !important;
  }
  
  .togglebtn {
    font-size: 27px !important;
    margin-left: 10px;
    color: $toggle-btn-color;
  }
  
  .toggleBtnText {
    margin-left: 10px;
    font-weight: 600;
  }
  
  .cronStringText {
    color: $cron-string-text-color;
    font-size: 1rem;
  }
  
  .cronStringContainer {
    .card-header {
      padding: 0.45rem 0.75rem;
      border-bottom: 1px solid $cron-string-container-header-border !important;
    }
    .card-body {
      padding: 0.5rem;
    }
  }
  
  .jsonParameter {
    min-height: 150px;
  }
  
  .btnLoader {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include CreateSchedule($toggle-btn-color,$cron-string-text-color,$cron-string-container-header-border);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include CreateSchedule($toggle-btn-color,$cron-string-text-color,$cron-string-container-header-border);
}
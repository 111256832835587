@mixin ListView($border-color-red,$black-transparent,$border-color-light,$background-color-light,$background-color-white){
  .list-view {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px $black-transparent;
    border: 1px solid $border-color-light;
    border-left: 3px solid $border-color-red;
  }
  .alternate-row {
    background-color: $background-color-light; 
  }
  .section-row {        
    display: flex;
    flex-wrap: wrap;
  }
  .section {
    flex: 1;
    padding: 10px;
  }
  .unorder-list{
    list-style: none;
  }
  .label-value-pair{
    display: flex;
  }
  .label-value-pair{
  .label {
    flex: 2; 
    font-weight: 700;
    text-align: left;
    }
  }
  .boldTag{
    font-weight: 700 !important;
  }
  .value {
    flex: 1;
    text-align: left; 
  }
  .ellipsis-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .drawer-container {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    background: $background-color-white;  
    box-shadow: 0px 0px 5px $black-transparent;
  }
  .ellipsis-button {
    position: relative;
  }
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include ListView($border-color-red,$black-transparent,$border-color-light,$background-color-light,$background-color-white);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include ListView($border-color-red,$black-transparent,$border-color-light,$background-color-light,$background-color-white);
}

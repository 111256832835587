// all common imports;

@mixin Search($search-filter-options-background,$search-filter-options-border,$search-filter-options-grid-color,$search-filter-options-grid-background,$search-filter-options-input-background) {
  .search-filter {
    .show-options {
      padding: 12px 8px 3px;
      background: $search-filter-options-background;
      border-radius: 2px;
      border: 1px solid $search-filter-options-border;
      position: absolute;
      width: 140px;
      top: 5px;
      .grid-options {
        select {
          padding: 2px 5px;
          border: 2px solid;
          width: 100%;
          border-radius: 2px;
          color: $search-filter-options-grid-color;
          font-size: 14px;
          background: $search-filter-options-grid-background;
        }
      }
      .input-sm {
        padding: 10px;
        width: 100%;
        font-size: 14px;
        border-radius: 2px;
        margin: 5px 0px;
        background: $search-filter-options-input-background;
      }
    }
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Search($search-filter-options-background,$search-filter-options-border,$search-filter-options-grid-color,$search-filter-options-grid-background,$search-filter-options-input-background);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Search($search-filter-options-background,$search-filter-options-border,$search-filter-options-grid-color,$search-filter-options-grid-background,$search-filter-options-input-background);
}
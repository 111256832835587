// all common imports;

@mixin Header($user-logo-background, $user-logo-border, $user-logo-text, $company-profile-header-link, $company-profile-header-link-hover, $header-toggle-link-color,$border-color-medium,$black) {

  #root{
    $iconColor : $black;
    $userLogoBackground : $user-logo-background;
  }

  .user_logo {
    height: 36px;
    width: 36px;
    background: var(--userLogoBackground);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $user-logo-border;
  }
  
  .user_logo_text {
    color: $user-logo-text;
    font-size: 18px;
  }
  
  .header-icons-container {
    height: 60px;
  }
  
  .companyProfileHeaderIcon {
    font-size: 1.5em;
  }
  
  .header-dropdown .dropdown-toggle::after {
    display: none !important;
  }
  
  .companyProfileHeaderLink, .switchThemeHeaderIcon {
    color: var(--iconColor) !important;
  }
  
  .companyProfileHeaderLink:hover, .switchThemeHeaderIcon:hover {
    color: $company-profile-header-link-hover;
  }
  
  .header .toggle a {
    color: var(--iconColor) !important;
  }

  .userProfileHeaderDropdown{
    padding-left:10px;
    border-left: 1px solid $border-color-medium;
  }
  
}


html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Header($user-logo-background, $user-logo-border, $user-logo-text, $company-profile-header-link, $company-profile-header-link-hover, $header-toggle-link-color,$border-color-medium,$black);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Header($user-logo-background, $user-logo-border, $user-logo-text, $company-profile-header-link, $company-profile-header-link-hover, $header-toggle-link-color,$border-color-medium,$black);
}
// Import your styles at the top level
@import "../../../styles/themes/theme-default.scss";
@import "../../../styles/themes/theme-dark.scss";
@import "../../../styles/theme-color-variables.scss";

// Define your mixin
@mixin ViewPageComponent($text-color) {

    #root{
        $formHeaderColor : $text-color;
    }

    .content {
        color: var(--formHeaderColor);
        font-size: 16px;
        font-weight: 600;
    }

    .border {
        border: 2px solid $text-color;
        border-radius: 10px;
    }
}

// Use the mixin for different themes
html.default {
    @include ViewPageComponent($gray);
}

html.dark {
    @include ViewPageComponent($gray);
}

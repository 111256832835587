@import "../../../node_modules/bootstrap/scss/_functions.scss";

// Color system

// color-variables
$white:    #fff !default;
$white-transparent: #ffffff0f !default;
$white-partial-transparent: #ffffff96 !default;
$black:    #000 !default;
$black-transparent: #00000033 !default;
$black-transparent1: #0000001a !default;
$black-transparent2: #00000040 !default;
$gray:  #9E9E9E !default;
$custom-light-gray: #f6f6f6 !default;
$blue:    #2196F3 !default;
$indigo:  #3F51B5 !default;
$purple:  #9C27B0 !default;
$pink:    #E91E63 !default;
$red:     #EF5350 !default;
$orange:  #FF9800 !default;
$yellow:  #FFEB3B !default;
$green:   #66BB6A !default;
$light-green:   #8BC34A !default;
$teal:    #009688 !default;
$cyan:    #00BCD4 !default;
$blue-gray:    #607D8B !default;
$custom-light-blue-gray: #f6f7fb !default;
$deep-purple: #673AB7 !default;
$light-blue: #03A9F4 !default;
$lime: #CDDC39 !default;
$amber: #FFC107 !default;
$deep-orange: #FF5722 !default;

// All different shades of theme colorts
// Shades of Gray
$gray-50: tint-color($gray, 90%) !default;
$gray-100: tint-color($gray, 80%) !default;
$gray-200: tint-color($gray, 60%) !default;
$gray-300: tint-color($gray, 40%) !default;
$gray-400: tint-color($gray, 20%) !default;
$gray-500: $gray !default;
$gray-600: shade-color($gray, 20%) !default;
$gray-700: shade-color($gray, 40%) !default;
$gray-800: shade-color($gray, 60%) !default;
$gray-900: shade-color($gray, 80%) !default;

$blue-50: tint-color($blue, 90%) !default;
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-50: tint-color($indigo, 90%) !default;
$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-50: tint-color($purple, 90%) !default;
$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-50: tint-color($pink, 90%) !default;
$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-50: tint-color($red, 90%) !default;
$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-50: tint-color($orange, 90%) !default;
$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-50: tint-color($yellow, 90%) !default;
$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-50: tint-color($green, 90%) !default;
$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$light-green-50: tint-color($light-green, 80%) !default;
$light-green-100: tint-color($light-green, 80%) !default;
$light-green-200: tint-color($light-green, 60%) !default;
$light-green-300: tint-color($light-green, 40%) !default;
$light-green-400: tint-color($light-green, 20%) !default;
$light-green-500: $light-green !default;
$light-green-600: shade-color($light-green, 20%) !default;
$light-green-700: shade-color($light-green, 40%) !default;
$light-green-800: shade-color($light-green, 60%) !default;
$light-green-900: shade-color($light-green, 80%) !default;

$teal-50: tint-color($teal, 90%) !default;
$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-50: tint-color($cyan, 90%) !default;
$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$blue-gray-50: tint-color($blue-gray, 90%) !default;
$blue-gray-100: tint-color($blue-gray, 80%) !default;
$blue-gray-200: tint-color($blue-gray, 60%) !default;
$blue-gray-300: tint-color($blue-gray, 40%) !default;
$blue-gray-400: tint-color($blue-gray, 20%) !default;
$blue-gray-500: $blue-gray !default;
$blue-gray-600: shade-color($blue-gray, 20%) !default;
$blue-gray-700: shade-color($blue-gray, 40%) !default;
$blue-gray-800: shade-color($blue-gray, 60%) !default;
$blue-gray-900: shade-color($blue-gray, 80%) !default;

// scss-docs-start theme-color-variables
$primary:       $blue !default;
$secondary:     $blue-gray !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
// scss-docs-end theme-color-variables


// ****************************************
// Application common theme colors
// ****************************************

// Common colors for font, background and border
$font-color-white: $white;
$font-color-black: $black;
$font-color-light-blue: $blue-100;
$font-color-blue: $blue-500;
$font-color-red: $red-400;
$font-color-green: $green-500;
$font-color-teal: $teal-500;
$font-color-orange: $orange-300;
$font-color-purple: $purple-500;
$font-color-dark-700: $blue-gray-700;
$font-color-light: $blue-gray-50;
$font-color-medium: $blue-gray-500;
$font-color-dark: $blue-gray-800;
$font-color-yellow: $yellow-600;

$background-color-white: $white;
$background-color-black: $black;
$background-color-blue: $blue-500;
$background-color-medium-blue: $blue-300;
$background-color-light-blue: $blue-100;
$background-color-red: $red-400;
$background-color-light-red: $red-100;
$background-color-green: $green-500;
$background-color-teal: $teal-500;
$background-color-orange: $orange-300;
$background-color-purple: $purple-500;
$background-color-dark-700: $blue-gray-700;
$background-color-light: $blue-gray-50;
$background-color-medium: $blue-gray-500;
$background-color-dark: $blue-gray-800;
$background-color-gray: $gray-50;

$border-color-white: $white;
$border-color-black: $black;
$border-color-blue: $blue-500;
$border-color-light-blue: $blue-100;
$border-color-red: $red-400;
$border-color-green: $green-500;
$border-color-orange: $orange-500;
$border-color-purple: $purple-500;
$border-color-dark-700: $blue-gray-700;
$border-color-light: $blue-gray-100;
$border-color-medium: $blue-gray-500;
$border-color-dark: $blue-gray-800;

$heading-background-white: $white;
$heading-background-black: $black;
$heading-background-blue: $blue-500;
$heading-background-light-blue: $blue-100;
$heading-background-red: $red-400;
$heading-background-green: $green-500;
$heading-background-purple: $purple-500;
$heading-background-dark-700: $blue-gray-700;
$heading-background-light: $blue-gray-50;
$heading-background-medium: $blue-gray-500;
$heading-background-dark: $blue-gray-800;

$border-color-default: $blue-gray-100;
$heading-color-white: $white;
$heading-color-black: $black;
$heading-color-blue: $blue-500;
$heading-color-light-blue: $blue-100;
$heading-color-red: $red-400;
$heading-color-green: $green-500;
$heading-color-purple: $purple-500;
$heading-color-dark-700: $blue-gray-700;
$heading-color-light: $blue-gray-50;
$heading-color-medium: $blue-gray-500;
$heading-color-dark: $blue-gray-800;

$shadow-color-blue: $blue-300;
$shadow-color-red: $red-400;
$shadow-color-green: $green-500;
$shadow-color-purple: $purple-500;
$shadow-color-dark-700: $blue-gray-700;
$shadow-color-light: $blue-gray-50;
$shadow-color-medium: $blue-gray-500;
$shadow-color-dark: $blue-gray-800;

$webkit-scrollbar-background-light: $blue-gray-50;
$webkit-scrollbar-background-medium: $blue-gray-100;
$webkit-scrollbar-background-dark: $blue-gray-300;

$no-data-found-color: $blue-gray-500;

// Body
$body-default-color: $font-color-dark;
$body-default-background: $white;
$body-background: $custom-light-blue-gray;
$center-loader-background: rgba($custom-light-blue-gray, 0.35);
$background-color: $white !default;

// ReactUI
$reactui-control-label-color: $font-color-medium;
$reactui-update-view-button-color: $font-color-medium;
$reactui-custom-label-color:  $font-color-dark;
$reactui-custom-content-color:  $font-color-medium;
$reactui-tab-active-background: $background-color-white;
$reactui-tab-active-box-shadow: $background-color-light-blue;
$reactui-tab-active-hover-color: $font-color-dark;
$reactui-display-component-ui-background: $background-color-white;
$reactui-custom-comment-input-border: $border-color-light;

// Menu
$menu-background-color: $background-color-blue;
// all common imports;

@mixin ProgressList($no-data-found-color) {
  .progressContainer {
    .textContainer {
      width: 35%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .progreessBar {
      width: 65%;
    }
  }
  .progreeIndicator {
    width: 15px;
    height: 15px;
    border-radius: 3px;
  }
  
  .no-data{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: $no-data-found-color;
    font-size: 12px;
    font-weight: bold;
    border-top: 0px !important;
  }
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include ProgressList($no-data-found-color);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include ProgressList($no-data-found-color);
}
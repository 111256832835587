// all common imports;

@mixin ProductListing($product-listing-circle-color,	$product-listing-round-cirle-color,	$product-listing-round-cirle-background,	$product-listing-circle-header-background,	$product-add-border,	$circle-active-background,	$stepper-background-dark,	$stepper-border-light) {
  .circle {
    color: $product-listing-circle-color;
    font-size: 18px;
    display: flex;
    cursor: pointer;
  }
  
  .circle::after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    height: 1px;
    width: 500px;
    background: $product-listing-circle-color;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  
  .round-cirle {
    border-radius: 20px;
    width: 25px;
    height: 25px;
    color: $product-listing-round-cirle-color;
    background: $product-listing-round-cirle-background;
    font-size: 10px;
    padding: 5px 9px;
  }
  .headers {
    position: relative;
  }
  
  .headers:after {
    content: "";
    background-color: $product-listing-circle-header-background;
    display: inline-block;
    height: 1px;
    position: absolute;
    width: 600%;
    left: 154%;
    top: 12px;
  }
  
  .add-product {
    border: 1px solid $product-add-border;
    padding: 20px;
  }
  fieldset {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .form-group.field.field-string,
    .form-group.field.field-object .form-group.field.field-object,
    .form-group.field.field-number,
    .form-group.field.field-array {
      width: 48%;
      margin-bottom: 5px;
    }
  }
  
  // product listing
  .md-stepper-horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .md-stepper-horizontal .md-step {
    display: flex;
    width: 33%;
    position: relative;
    padding: 10px 0px 15px 0px;
    cursor: pointer;
  }
  
  .md-stepper-horizontal .md-step .md-step-circle {
    width: 30px;
    height: 30px;
    background-color: $product-listing-round-cirle-background;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    color: $product-listing-round-cirle-color;
    cursor: pointer;
  }
  
  .circle-active {
    background-color: $circle-active-background !important;
  }
  
  .md-step {
    display: flex;
    align-items: center;
  }
  
  .md-stepper-horizontal .md-step .md-step-title {
    margin: 0px;
    margin-left: 20px;
    font-size: 16px;
  }
  .md-stepper-horizontal .md-step.active .md-step-title {
    color: rgba($stepper-background-dark, 0.87);
  }
  
  .md-stepper-horizontal .md-step .md-step-optional {
    font-size: 12px;
  }
  .md-stepper-horizontal .md-step.active .md-step-optional {
    color: rgba($stepper-background-dark, 0.54);
  }
  .md-stepper-horizontal .md-step .md-step-bar-left,
  .md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 1px solid $stepper-border-light;
  }
  .md-stepper-horizontal .md-step .md-step-bar-right {
    right: 0;
    left: 55%;
    width: 30%;
  }
  .head-row > .table_head:first-child > i,
  .head-row > .table_head:last-child > i {
    display: none;
  }
  .filterSize {
    font-size: 24px;
  }
  .noAttributesRecord {
    font-size: medium;
    font-weight: 500;
  }
  
  @media only screen and (max-width: 480px) {
    .product-responsive-heading,
    .responsive-heading {
      h5 {
        font-size: 14px;
        font-weight: bold;
      }
      .bread-crum {
        margin: 10px 0px !important;
        h6,
        h2 p {
          font-size: 12px;
          font-weight: bold;
          margin: 0px !important;
        }
        i {
          margin: 0px 5px !important;
        }
      }
    }
    .responsive-button {
      .filterSize {
        font-size: 18px;
      }
      button {
        font-size: 10px;
        font-weight: bold;
        padding: 5px;
      }
    }
    .catlog-product-view {
      .button-wrapper {
        padding: 0px !important;
        h2 {
          margin: 0px;
          font-size: 16px;
          font-weight: bold;
        }
        button {
          margin: 0px;
          font-size: 10px;
          font-weight: bold;
        }
        .md-step.active {
          padding: 10px 0px;
        }
        .md-step-bar-right {
          display: none;
        }
        .md-step-title {
          margin: 0px 5px !important;
          font-size: 12px !important;
          font-weight: bold;
        }
        .md-step-circle {
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 10px;
          font-weight: bold;
        }
      }
    }
    .product-list {
      p {
        margin: 0px !important;
      }
    }
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include ProductListing($product-listing-circle-color,	$product-listing-round-cirle-color,	$product-listing-round-cirle-background,	$product-listing-circle-header-background,	$product-add-border,	$circle-active-background,	$stepper-background-dark,	$stepper-border-light);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include ProductListing($product-listing-circle-color,	$product-listing-round-cirle-color,	$product-listing-round-cirle-background,	$product-listing-circle-header-background,	$product-add-border,	$circle-active-background,	$stepper-background-dark,	$stepper-border-light);
}
// all common imports;

@mixin Login($login-main-background,	$login-main-input-border-color,	$login-main-input-col-md-color-h2,	$login-main-content-border-left,	$login-main-content-control-label,	$login-right-side-col-background,	$login-main-button-background,	$login-backimg-background,	$login-button-alignment-hover-color,	$shimmer-background, $shimmer-background1,	$forgot-password-background)
{
  .login_main {
    background: $login-main-background;
  
    input {
      display: block;
      text-transform: none;
      width: 100%;
      height: 35px;
      border-width: 0 0 1px;
      border-style: solid;
      border-color: $login-main-input-border-color;
      font-weight: 400;
      border-radius: 0 !important;
    }
  
    .col-md-12 {
      h2 {
        color: $login-main-input-col-md-color-h2;
      }
      .col-md-7 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .right-side-col {
        .login_main_content {
          .logo {
            height: 20%;
            width: 20%;
            margin-bottom: 2rem;
          }
          height: 90vh;
          flex-wrap: wrap;
          align-content: center;
          display: flex;
          border-left: 1px solid $login-main-content-border-left;
          padding-left: 2rem;
          .control-label {
            font-size: 16px;
            color: $login-main-content-control-label;
          }
        }
        height: 100vh;
        background: $login-right-side-col-background;
        padding: 2rem;
        .form_content {
          .logo-img {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            img {
              width: 25%;
              margin: 0 auto;
            }
          }
        }
      }
      .footer_title {
        p {
          color: $login-main-content-control-label;
          font-weight: bold;
          font-size: 12px;
          float: right;
        }
      }
      button {
        border: 0px;
        padding: 4px 15px;
      }
    }
  
    .backimg {
      width: 100%;
      height: 100vh;
      object-fit: contain;
      background: $login-backimg-background;
    }
    .img-logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      margin-bottom: 50px;
    }
  }
  
  .buttonAlignment {
    text-align: right;
    padding: 6px;
  }
  
  .buttonAlignment:hover {
    color: $login-button-alignment-hover-color;
  }
  
  .forgotPasswordRow{
    .login_main_content{
      .logo{
        height: 20%;
        width: 20%;
      }
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .forgotPasswordRow {
    .login_main_content {
      .logo {
        height: 20%;
        width: 20%;
        margin-bottom: 2rem;
      }
      height: 90vh;
      flex-wrap: wrap;
      align-content: center;
      display: flex;
      border-left: none;
      padding-left: 2rem;
      .control-label {
        font-size: 16px;
        color: $login-main-content-control-label;
      }
    }
    height: 100vh;
    background: $login-main-background;
    padding: 2rem;
    .form_content {
      .logo-img {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        img {
          width: 25%;
          margin: 0 auto;
        }
      }
    }
  }
  
  .shimmerLogo {
    height: 200px;
    width: 100%;
  }
  
  .shimmerBanner {
    height: 400px;
    width: 100%;
  }
  
  .forgotPasswordShimmerBanner{
    height: 250px;
    width: 100%;
  }
  
  .shimmer {
    margin: 20px;
    flex: auto;
    background: $shimmer-background;
    background-image: linear-gradient(
      to right,
      $shimmer-background 20%,
      $shimmer-background1 40%,
      $shimmer-background 60%,
      $shimmer-background 100%
    );
    background-size: 800px 104px;
    display: inline-block;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }
  
  @keyframes placeholderShimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  
  .logoContainer {
    min-height: 100px;
    width: 250px;
    height: 230px;
    margin: auto;
    margin-bottom: 20px;
  }
  
  .logoContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .forgotPasswordRow{
    background: $forgot-password-background;
    justify-content: center;
  }
  
}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include Login($login-main-background,	$login-main-input-border-color,	$login-main-input-col-md-color-h2,	$login-main-content-border-left,	$login-main-content-control-label,	$login-right-side-col-background,	$login-main-button-background,	$login-backimg-background,	$login-button-alignment-hover-color,	$shimmer-background, $shimmer-background1,	$forgot-password-background);
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include Login($login-main-background,	$login-main-input-border-color,	$login-main-input-col-md-color-h2,	$login-main-content-border-left,	$login-main-content-control-label,	$login-right-side-col-background,	$login-main-button-background,	$login-backimg-background,	$login-button-alignment-hover-color,	$shimmer-background, $shimmer-background1,	$forgot-password-background);
}
// all common imports;

@mixin ProductList($product-wrapper-border,$product-wrapper-background,$product-wrapper-top-content-color,$product-wrapper-dynamic-border,$product-wrapper-btn-theme-background,$product-wrapper-btn-theme-color) {
  .view-icon {
    font-size: 20px;
    margin-left: 10px;
  }
  
  .product-wrapper {
    border: 0.5px solid $product-wrapper-border;
    background: $product-wrapper-background;
    border-radius: 3px;
    .img-wrapper {
      height: 170px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 3px;
      }
    }
    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  
    .top-content {
      color: $product-wrapper-top-content-color;
    }
  }
  .dynamic-border {
    border-right: 0.5px solid $product-wrapper-dynamic-border;
  }
  
  .btn-theme {
    background: $product-wrapper-btn-theme-background !important;
    color: $product-wrapper-btn-theme-color !important;
  }
  
  @media (max-width: 768px) {
    .responsive-header {
      margin-top: 1rem;
    }
    .dynamic-border {
      border: none !important;
    }
    .product-wrapper .img-wrapper {
      height: 250px;
    }
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include ProductList($product-wrapper-border,$product-wrapper-background,$product-wrapper-top-content-color,$product-wrapper-dynamic-border,$product-wrapper-btn-theme-background,$product-wrapper-btn-theme-color);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include ProductList($product-wrapper-border,$product-wrapper-background,$product-wrapper-top-content-color,$product-wrapper-dynamic-border,$product-wrapper-btn-theme-background,$product-wrapper-btn-theme-color);
}
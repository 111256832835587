// all common imports;

@mixin Charts($arrow-icon-color) {
  .chart-border {
    border-radius: 20px;
  }
  
  .chart-header {
    position: absolute;
    right: 10px;
    top: 0px;
  }
  
  .arrow-icon-container {
    font-size: 20px;
    width: 30px;
    transition: 0.2s;
    cursor: pointer;
  }
  
  .arrow-icon-chart-container {
    position: absolute;
    right: 14px;
  }
  
  .arrow-icon {
    color: 1px solid $arrow-icon-color !important;
    display: inline-block;
    transition: transform 0.5s;
  }
  
  .arrow-icon-up {
    transform: rotate(-180deg);
  }
  
  .arrow-icon-down {
    transform: rotate(0);
  }
  
  .linkToIconChart {
    position: absolute;
    right: 50px;
  }
  
  .reload-button{
    position: absolute;
    right: 5px;
    top:0px;
    visibility: hidden;
  }
  
}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include Charts($arrow-icon-color);
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include Charts($arrow-icon-color);
}

// all common imports;

@mixin InLineEdit($inline-edit-border,$inline-edit-background,$inline-edit-hover-background) {
  .inLineEdit {
    .editIconContainer {
      opacity: 0.2;
      transition: 0.2s;
    }
    .editIcon {
      display: flex;
      height: 20px;
      width: 20px;
      align-items: center;
      justify-content: center;
      border: 1px solid $inline-edit-border;
      border-radius: 5px;
      transition: 0.2s all;
      font-size: 12px;
      background: $inline-edit-background;
    }
    .editIcon:hover {
      background: $inline-edit-hover-background;
    }
    .inLineMinWidth {
      min-width: 150px;
    }
  }
  
  .inLineEdit:hover {
    .editIconContainer {
      opacity: 1;
    }
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include InLineEdit($inline-edit-border,$inline-edit-background,$inline-edit-hover-background);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include InLineEdit($inline-edit-border,$inline-edit-background,$inline-edit-hover-background);
}
// all common imports;

@mixin ProductMap($product-map-background, $product-map-markers-color, $product-map-marker-model-color,$product-map-marker-model-background, $product-map-marker-model-border, $related-products-border,$related-products-background) {
  .product-map {
    background-color: $product-map-background;
    button.gm-control-active.gm-fullscreen-control {
      display: none;
    }
    .w-40 {
      width: 40%;
    }
    .img-height {
      height: 200px;
      img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
      }
    }
    .markers {
      font-size: 2.5rem;
      color: $product-map-markers-color;
      .marker-model {
        font-size: 10px;
        width: 100px;
        color: $product-map-marker-model-color;
        background: $product-map-marker-model-background;
        padding: 5px 10px;
        font-weight: bold;
        border: 1px solid $product-map-marker-model-border;
      }
    }
    .map-view {
      height: 600px;
    }
    .related-products {
      margin-top: 5rem;
      .carousal-img-wrapper {
        width: 100%;
        padding: 10px 20px;
        border: 1px solid $related-products-border;
        border-radius: 0px;
        background-color: $related-products-background;
        cursor: pointer;
        .related-img-wrapper {
          height: 200px;
          width: 100%;
          margin-top: 15px;
        }
        img {
          height: 200px;
          width: 100%;
          margin-top: 15px;
        }
      }
    }
  }
  
  @media screen and (max-width: 600px) {
    .map-view {
      height: 500px !important;
    }
    .responsive-margin {
      margin-top: 20px;
    }
  }
}

html.default {
  @import "../../../../../styles/themes/theme-default.scss";
  @import "../../../../../styles/theme-color-variables.scss";
  @include ProductMap($product-map-background, $product-map-markers-color, $product-map-marker-model-color,$product-map-marker-model-background, $product-map-marker-model-border, $related-products-border,$related-products-background);
}
html.dark {
  @import "../../../../../styles/themes/theme-dark.scss";
  @import "../../../../../styles/theme-color-variables.scss";
  @include ProductMap($product-map-background, $product-map-markers-color, $product-map-marker-model-color,$product-map-marker-model-background, $product-map-marker-model-border, $related-products-border,$related-products-background);
}
// all common imports;

@mixin Category($store-category-border,$count-badge-background,$count-badge-color) {
  .main-category {
    .store-category {
      border: 1.5px solid $store-category-border;
      border-radius: 5px;
      cursor: pointer;
      .add-wrapper {
        font-size: 15px;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 4rem;
        }
      }
      p {
        font-size: 16px;
        padding: 10px 0px 20px 0px;
      }
    }
  }
  
  .countBadge {
    height: 25px;
    width: 25px;
    background: $count-badge-background;
    border-radius: 50%;
    color: $count-badge-color;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -9px;
    right: -9px;
    font-weight: 600;
    font-size: small;
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Category($store-category-border,$count-badge-background,$count-badge-color);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Category($store-category-border,$count-badge-background,$count-badge-color);
}
// all common imports;

@mixin Tenant($customer-tenant-main-background,$customer-tenant-main-h2-color,$customer-tenant-main-content-border,$customer-tenant-main-control-label,$customer-tenant-main-footer-title-color,$customer-tenant-main-back-img-background) {
  .tenant_main {
    background: $customer-tenant-main-background;
    .col-md-12 {
      h2 {
        color: $customer-tenant-main-h2-color;
      }
       
      .right-side-col {
        height: 100%;
        .tenant_main_content {
          .logo {
            padding: 5%;
            margin-bottom: 2rem;
          }
          flex-wrap: wrap;
          border-left: 2px solid $customer-tenant-main-content-border;
          align-content: center;
          display: flex;
          padding-left: 2rem;
          .control-label {
            font-size: 16px;
            color: $customer-tenant-main-control-label;
          }
        }
        min-height: 100vh;
        background: $customer-tenant-main-background;
        padding: 1rem;
        .form_content {
          .logo-img {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            img {
              width: 25%;
              margin: 0 auto;
            }
          }
        }
      }
      .footer_title {
        p {
          color: $customer-tenant-main-footer-title-color;
          font-weight: bold;
          font-size: 12px;
          float: right;
        }
      }
      .tensub {
        border: 0px;
        padding: 4px 15px;
        float: right;
      }
    }
    .backimg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: $customer-tenant-main-back-img-background;
    }
  }
  
  .flashcorpLogo {
    max-width: 200px;
  }
}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include Tenant($customer-tenant-main-background,$customer-tenant-main-h2-color,$customer-tenant-main-content-border,$customer-tenant-main-control-label,$customer-tenant-main-footer-title-color,$customer-tenant-main-back-img-background);
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include Tenant($customer-tenant-main-background,$customer-tenant-main-h2-color,$customer-tenant-main-content-border,$customer-tenant-main-control-label,$customer-tenant-main-footer-title-color,$customer-tenant-main-back-img-background);
}
/* *********  form textarea  **************************** */
// all common imports;

@mixin Form($form-color,	$form-btn-text-shadow,	$form-btn-background2,	$form-btn-background1,$form-btn-background3, $form-btn-background4,	$form-btn-border1, $form-btn-border2,	$form-btn-border,	$form-btn-border-bottom,	$webkit-box-shadow1, $webkit-box-shadow2,	$tags-input-border,	$tags-input-background,	$span-tag-background,	$span-tag-color,	$tagsinput-span-tag-link-color,	$tags-input-color,	$form-not-valid-background,	$form-not-valid-color,	$form-background) {
  .dataset_form {
    textarea {
      resize: none;
    }
    .btn_ {
      display: inline-block;
      padding: 3px 9px;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      color: $form-color;
      text-shadow: 0 1px 1px $form-btn-text-shadow;
      background-color: $form-btn-background2;
      background-image: -moz-linear-gradient(top, $form-btn-background1, $form-btn-background3);
      background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from($form-btn-background1),
        to($form-btn-background3)
      );
      background-image: -webkit-linear-gradient(top, $form-btn-background1, $form-btn-background3);
      background-image: -o-linear-gradient(top, $form-btn-background1, $form-btn-background3);
      background-image: linear-gradient(to bottom, $form-btn-background1, $form-btn-background3);
      background-repeat: repeat-x;
      filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$form-btn-background1}', endColorstr='#{$form-btn-background3}', GradientType=0)";
      border-color: $form-btn-background3 $form-btn-background3 $form-btn-background4;
      border-color: $form-btn-border1 $form-btn-border1 $form-btn-border2;
      filter: "progid:DXImageTransform.Microsoft.gradient(enabled=false)";
      border: 1px solid $form-btn-border;
      border-bottom-color: $form-btn-border-bottom;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 0 $webkit-box-shadow1,
        0 1px 2px $webkit-box-shadow2;
      -moz-box-shadow: inset 0 1px 0 $webkit-box-shadow1,
        0 1px 2px $webkit-box-shadow2;
      box-shadow: inset 0 1px 0 $webkit-box-shadow1,
        0 1px 2px $webkit-box-shadow2;
    }
  
    /* *********  /form textarea  **************************** */
  
    /* *********  form tags input  **************************** */
  
    .tagsinput {
      border: 1px solid $tags-input-border;
      background: $tags-input-background;
      padding: 6px 6px 0;
      width: 300px;
      overflow-y: auto;
    }
    span.tag {
      border-radius: 2px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      display: block;
      float: left;
      padding: 5px 9px;
      text-decoration: none;
      background: $span-tag-background;
      color: $span-tag-color;
      margin-right: 5px;
      font-weight: 500;
      margin-bottom: 5px;
      font-family: "DM Sans", sans-serif;
    }
    span.tag a {
      color: $span-tag-color !important;
    }
    .tagsinput span.tag a {
      font-weight: bold;
      color: $tagsinput-span-tag-link-color;
      text-decoration: none;
      font-size: 11px;
    }
    .tagsinput input {
      width: 80px;
      margin: 0px;
      font-family: "DM Sans", sans-serif;
      font-size: 13px;
      border: 1px solid transparent;
      padding: 3px;
      background: transparent;
      color: $tags-input-color;
      outline: 0px;
    }
    .tagsinput div {
      display: block;
      float: left;
    }
    .tags_clear {
      clear: both;
      width: 100%;
      height: 0px;
    }
    .not_valid {
      background: $form-not-valid-background !important;
      color: $form-not-valid-color !important;
    }
    .wrapper {
      background: $form-background;
    }
    /* *********  /form tags input  **************************** */
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include Form($form-color,	$form-btn-text-shadow,	$form-btn-background2,	$form-btn-background1,$form-btn-background3, $form-btn-background4,	$form-btn-border1, $form-btn-border2,	$form-btn-border,	$form-btn-border-bottom,	$webkit-box-shadow1, $webkit-box-shadow2,	$tags-input-border,	$tags-input-background,	$span-tag-background,	$span-tag-color,	$tagsinput-span-tag-link-color,	$tags-input-color,	$form-not-valid-background,	$form-not-valid-color,	$form-background);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include Form($form-color,	$form-btn-text-shadow,	$form-btn-background2,	$form-btn-background1,$form-btn-background3, $form-btn-background4,	$form-btn-border1, $form-btn-border2,	$form-btn-border,	$form-btn-border-bottom,	$webkit-box-shadow1, $webkit-box-shadow2,	$tags-input-border,	$tags-input-background,	$span-tag-background,	$span-tag-color,	$tagsinput-span-tag-link-color,	$tags-input-color,	$form-not-valid-background,	$form-not-valid-color,	$form-background);
}
// all common imports;

@mixin ProductType($span-tags-input-tag-background,	$span-tags-input-tag-color,	$product-border-color,	$close-icon-color,	$close-icon-background,	$close-icon-border,	$tag_input_color,	$tag_input_background,	$tag_input_border,	$webkit-scrollbar-track-dark,	$webkit-scrollbar-thumb-light,	$body-default-color,	$body-default-background,	$tag_active_suggestion_background,	$heading-credits) {
  span.react-tagsinput-tag {
    background: $span-tags-input-tag-background;
    color: $span-tags-input-tag-color;
    border: none;
  }
  
  .react-tagsinput--focused {
    border-color: $span-tags-input-tag-background;
  }
  
  .add-standard-product,
  .customized-product {
    .form-control {
      font-size: 14px;
    }
    .css-1pahdxg-control {
      border-color: $product-border-color !important;
      box-shadow: none;
    }
    .css-1pahdxg-control:hover {
      border-color: none !important;
      box-shadow: none;
    }
  }
  
  .image-video-product {
    .product-image {
      position: relative;
      margin-right: 10px;
      .close-icon {
        display: none;
        position: absolute;
        top: -28px;
        right: 3px;
        color: $close-icon-color;
        background: $close-icon-background;
        padding: 2px;
        border: 1px solid $close-icon-border;
        border-radius: 50px;
        height: 20px;
        width: 20px;
        cursor: pointer;
        i {
          left: 4px;
          top: 2px;
          position: absolute;
          font-size: 14px;
          color: $close-icon-color;
        }
      }
      .video-close {
        top: 28px;
        left: 3.5rem;
      }
    }
    .product-image:hover {
      cursor: pointer;
      .close-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  
    .brochure {
      display: flex;
      align-items: center;
      color: $span-tags-input-tag-background;
      margin-top: 5px;
  
      .pdf-file {
        font-size: 22px;
        margin-right: 3px;
        margin-top: 4px;
      }
  
      .remove-brochure {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
  
  // Tags Input
  div.ReactTags__tags {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  
  /* Styles for the input */
  div.ReactTags__tagInput {
    border-radius: 2px;
    .ReactTags__tagInputField {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: $tag_input_color;
      background-color: $tag_input_background;
      background-clip: padding-box;
      border: 1px solid $tag_input_border;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
  
  .ReactTags__tagInput {
    width: 23%;
  }
  
  .ReactTags__selected {
    width: 75%;
    height: 78px;
    border: 1px solid $tag_input_border;
    border-radius: 10px;
    overflow-y: scroll;
  }
  .ReactTags__selected::-webkit-scrollbar {
    width: 5px;
  }
  
  .ReactTags__selected::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-track-dark;
    border-radius: 100px;
  }
  
  .ReactTags__selected::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: $webkit-scrollbar-thumb-light;
  }
  
  div.ReactTags__selected span.ReactTags__tag {
    background: $tag_input_border;
    font-size: 10px;
    padding: 6px;
    color: $body-default-color !important;
    cursor: default !important;
    margin: 5px;
    border-radius: 5px;
    display: inline-block;
    .ReactTags__remove {
      border: none;
      margin: 0px;
      padding: 0px;
      margin-left: 5px;
    }
  }
  div.ReactTags__selected a.ReactTags__remove {
    margin-left: 5px;
    cursor: pointer;
  }
  
  div.ReactTags__suggestions {
    display: block !important;
    position: absolute;
    color: $body-default-color !important;
    font-size: 11px !important;
    font-weight: normal !important;
    z-index: 9999;
  }
  div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: $body-default-background;
    width: 200px;
    padding: 0px !important;
    height: 70px;
    overflow-y: scroll;
    border: 1px solid $tag_input_border;
  }
  div.ReactTags__suggestions li {
    border-bottom: 1px solid $tag_input_border;
    padding: 7px 10px;
    margin: 0;
  }
  div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: $tag_active_suggestion_background;
    cursor: pointer;
  }
  
  // ------------card design---------------//@at-root
  
  .heading {
    text-align: center;
  }
  
  .heading__title {
    font-weight: 600;
  }
  
  .heading__credits {
    margin: 10px 0px;
    color: $heading-credits;
    font-size: 25px;
    transition: all 0.5s;
  }
  
  .heading__link {
    text-decoration: none;
  }
  
  .heading__credits .heading__link {
    color: inherit;
  }
  
  .w-80 {
    width: 80%;
  }
  
  .custom-checkbox {
    height: 16px;
    width: 16px;
  }
  
  .centerd {
    display: flex;
    align-items: center;
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include ProductType($span-tags-input-tag-background,	$span-tags-input-tag-color,	$product-border-color,	$close-icon-color,	$close-icon-background,	$close-icon-border,	$tag_input_color,	$tag_input_background,	$tag_input_border,	$webkit-scrollbar-track-dark,	$webkit-scrollbar-thumb-light,	$body-default-color,	$body-default-background,	$tag_active_suggestion_background,	$heading-credits);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include ProductType($span-tags-input-tag-background,	$span-tags-input-tag-color,	$product-border-color,	$close-icon-color,	$close-icon-background,	$close-icon-border,	$tag_input_color,	$tag_input_background,	$tag_input_border,	$webkit-scrollbar-track-dark,	$webkit-scrollbar-thumb-light,	$body-default-color,	$body-default-background,	$tag_active_suggestion_background,	$heading-credits);
}
// all common imports;

@mixin ProductView($share-wrapper-background,	$share-wrapper-color,	$ecommerce-custom-tag-background,	$ecommerce-custom-tag-color,	$ecommerce-price,	$related-products-border,	$get-quote-button-background,	$get-quote-button-color,	$get-quote-button-border,	$get-quote-button-hover-background,	$get-quote-button-hover-color) {
  .product-view {
    .view-icon {
      font-size: 20px;
      margin-left: 10px;
    }
  
    .img-wrapper {
      .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 100% !important;
        object-fit: cover;
      }
      .image-gallery-thumbnails-wrapper.bottom.thumbnails-swipe-horizontal {
        margin-top: 30px;
      }
      .image-gallery-slide {
        height: 400px;
      }
  
      .image-gallery-left-nav .image-gallery-svg,
      .image-gallery-right-nav .image-gallery-svg {
        height: 60px !important;
      }
    }
    .share-wrapper {
      cursor: pointer;
      border: none;
      background: $share-wrapper-background;
      font-size: 12px;
      padding: 8px 15px;
      border-radius: 5px;
      font-weight: bold;
      color: $share-wrapper-color;
      position: relative;
      .tooltip {
        left: 0;
        top: 43px;
        opacity: 1;
        .arrow {
          display: none;
        }
        .tooltip-inner {
          display: block;
          background-color: $share-wrapper-background;
          width: 100px;
        }
      }
    }
    .custom-tag {
      background: $ecommerce-custom-tag-background;
      padding: 5px 10px;
      font-size: 10px;
      border-radius: 10px;
      margin: 5px;
      color: $ecommerce-custom-tag-color;
    }
    .product_price_head {
      border-radius: 5px;
      padding: 10px;
      color: $ecommerce-price;
    }
    .related-products,
    .related-categories {
      margin-top: 5rem;
      .carousal-img-wrapper {
        width: 100%;
        padding: 10px;
        border: 1px solid $related-products-border;
        border-radius: 15px;
        cursor: pointer;
        .related-img-wrapper {
          height: 100px;
          width: 100%;
          margin-top: 30px;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: scale-down;
        }
      }
    }
    .getQuoteButton {
      height: 37.5px !important;
      background-color: $get-quote-button-background !important;
      color: $get-quote-button-color !important;
      border: 1px solid $get-quote-button-border;
    }
    .getQuoteButton:hover {
      background-color: $get-quote-button-hover-background !important;
      color: $get-quote-button-hover-color !important;
    }
    @media screen and (max-width: 600px) {
      .product-sub-head {
        h2 {
          font-size: 15px;
        }
        h6 {
          font-size: 12px;
        }
      }
    }
    .content-wrapper {
      font-size: 14px;
    }
  }
  
}

html.default {
  @import "../../../../../styles/themes/theme-default.scss";
  @import "../../../../../styles/theme-color-variables.scss";
  @include ProductView($share-wrapper-background,	$share-wrapper-color,	$ecommerce-custom-tag-background,	$ecommerce-custom-tag-color,	$ecommerce-price,	$related-products-border,	$get-quote-button-background,	$get-quote-button-color,	$get-quote-button-border,	$get-quote-button-hover-background,	$get-quote-button-hover-color);
}
html.dark {
  @import "../../../../../styles/themes/theme-dark.scss";
  @import "../../../../../styles/theme-color-variables.scss";
  @include ProductView($share-wrapper-background,	$share-wrapper-color,	$ecommerce-custom-tag-background,	$ecommerce-custom-tag-color,	$ecommerce-price,	$related-products-border,	$get-quote-button-background,	$get-quote-button-color,	$get-quote-button-border,	$get-quote-button-hover-background,	$get-quote-button-hover-color);
}
// all common imports;

@mixin AppDashboard($app-box-background,	$app-box-app-btn-color,	$app-box-app-btn-background,	$app-box-app-btn-warning-color,	$app-box-app-btn-warning-background,	$app-box-hover-shadow-color,	$pending-badge-background,	$btn-outline-blue-color,	$btn-outline-blue-border-color,	$bgc-white-background-color ,	$text-green-d1-color ,	$text-purple-d1-color ,	$text-primary-d1-color ,	$text-secondary-d1-color ,	$text-danger-m3-color ,	$text-dark-l1-color ,	$app-dashboard-xpanel-background,	$drawer-background,	$edit-button-list-color,	$edit-button-list-border-color,	$drawer-li-hover-background,	$app-actions-clone-button-hover ,	$app-actions-edit-button-hover) {
  
  #root {
    $boxBackground : $app-box-background;
  }
  
  .app-box {
    height: 100%;
    text-align: center;
    overflow: hidden;
    background: var(--boxBackground);
    padding: 15px 10px 5px 10px;
    border-radius:20px;
    transition: box-shadow 0.3s;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    .btn {
      border: none !important;
    }
    .app-logo-img {
      height: 100px;
      margin-bottom: 1rem;
      img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
      }
    }
    .app-btn {
      margin-top: 1rem;
      border: none;
      font-size: 12px;
      color: $app-box-app-btn-color;
      background: $app-box-app-btn-background;
      border-radius: 5px;
      cursor: pointer;
      padding: 5px 20px;
      margin-bottom: 0px;
    }
  
    .app-btn-warning {
      margin-top: 1rem;
      border: none;
      font-size: 12px;
      color: $app-box-app-btn-warning-color;
      background: $app-box-app-btn-warning-background;
      border-radius: 5px;
      cursor: pointer;
      padding: 5px 20px;
      margin-bottom: 0px;
    }
  }
  .app-box:hover {
    box-shadow: 0 0 11px $app-box-hover-shadow-color;
  }
  
  .pending-badge {
    height: 25px;
    width: 25px;
    background: $pending-badge-background;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -3px;
    right: 1px;
    font-weight: 600;
  }
  
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .btn-a-brc-tp:not(.disabled):not(:disabled).active,
  .btn-brc-tp,
  .btn-brc-tp:focus:not(:hover):not(:active):not(.active):not(.dropdown-toggle),
  .btn-h-brc-tp:hover,
  .btn.btn-f-brc-tp:focus,
  .btn.btn-h-brc-tp:hover {
    border-color: transparent;
  }
  .btn-outline-blue {
    color: $btn-outline-blue-color;
    border-color: $btn-outline-blue-border-color;
    background-color: transparent;
  }
  .btn {
    cursor: pointer;
    position: relative;
    z-index: auto;
    transition: color 0.15s, background-color 0.15s, border-color 0.15s,
      box-shadow 0.15s, opacity 0.15s;
  }
  .border-2 {
    border-width: 2px !important;
    border-style: solid !important;
    border-color: transparent;
  }
  .bgc-white {
    background-color: $bgc-white-background-color !important;
  }
  
  .text-green-d1 {
    color: $text-green-d1-color !important;
  }
  .letter-spacing {
    letter-spacing: 0.5px !important;
  }
  .font-bolder,
  .text-600 {
    font-weight: 600 !important;
  }
  .text-170 {
    font-size: 1.7em !important;
  }
  
  .text-purple-d1 {
    color: $text-purple-d1-color !important;
  }
  
  .text-primary-d1 {
    color: $text-primary-d1-color !important;
  }
  .text-secondary-d1 {
    color: $text-secondary-d1-color !important;
  }
  .text-180 {
    font-size: 1.8em !important;
  }
  .text-150 {
    font-size: 1.5em !important;
  }
  .text-danger-m3 {
    color: $text-danger-m3-color !important;
  }
  .rotate-45 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .position-l {
    left: 0;
  }
  .position-b,
  .position-bc,
  .position-bl,
  .position-br,
  .position-center,
  .position-l,
  .position-lc,
  .position-r,
  .position-rc,
  .position-t,
  .position-tc,
  .position-tl,
  .position-tr {
    position: absolute !important;
    display: block;
  }
  .mt-n475,
  .my-n475 {
    margin-top: -2.5rem !important;
  }
  .ms-35,
  .mx-35 {
    margin-left: 1.25rem !important;
  }
  
  .text-dark-l1 {
    color: $text-dark-l1-color !important;
  }
  .text-90 {
    font-size: 0.9em !important;
  }
  .text-left {
    text-align: left !important;
  }
  
  .mt-25,
  .my-25 {
    margin-top: 0.75rem !important;
  }
  
  .text-110 {
    font-size: 1.1em !important;
  }
  
  .deleted-text {
    text-decoration: line-through;
  }
  
  .app-dashboard > .x_panel {
    background-color: $app-dashboard-xpanel-background;
  }
  
  .parent {
    position: relative !important;
  }
  
  .parent .child {
    position: absolute;
    top: 0;
    right: 0;
  }
  .drawer {
    width: 80px;
    background: $drawer-background;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-inline-start: 0px !important;
  }
  .drawer li {
    width: 100%;
    padding: 2px 10px;
  }
  
  .parent > .btn:focus {
    box-shadow: none !important;
  }
  
  .editButton {
    position: absolute !important;
    padding-top: 29px !important;
    right: 22px !important;
    display: none;
  }
  
  .child:hover .editButton {
    display: initial;
  }
  .editButtonList {
    list-style: none;
    color: $edit-button-list-color;
    font-size: 14px;
    border: 1px solid $edit-button-list-border-color;
    margin-top: -34px !important;
  }
  .drawer li:hover {
    background: $drawer-li-hover-background;
  }
  .app_actions_edit_button {
    padding: 3px !important;
    margin: 0px !important;
    padding-left: 7px !important;
    text-align: start;
    border: none;
  }
  .app_actions_clone_button {
    display: inline-block;
    position: relative;
    padding: 3px !important;
    margin: 0px !important;
    padding-left: 7px !important;
    text-align: start;
    border: none;
  }
  .app_actions_clone_button:hover {
    border: none;
    background: $app-actions-clone-button-hover !important;
  }
  .app_actions_edit_button:hover {
    border: none;
    background: $app-actions-edit-button-hover !important;
  }
  
  @media screen and (max-width: 600px) {
    .app_actions_edit_button {
      display: none;
    }
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include AppDashboard($app-box-background,	$app-box-app-btn-color,	$app-box-app-btn-background,	$app-box-app-btn-warning-color,	$app-box-app-btn-warning-background,	$app-box-hover-shadow-color,	$pending-badge-background,	$btn-outline-blue-color,	$btn-outline-blue-border-color,	$bgc-white-background-color ,	$text-green-d1-color ,	$text-purple-d1-color ,	$text-primary-d1-color ,	$text-secondary-d1-color ,	$text-danger-m3-color ,	$text-dark-l1-color ,	$app-dashboard-xpanel-background,	$drawer-background,	$edit-button-list-color,	$edit-button-list-border-color,	$drawer-li-hover-background,	$app-actions-clone-button-hover ,	$app-actions-edit-button-hover);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include AppDashboard($app-box-background,	$app-box-app-btn-color,	$app-box-app-btn-background,	$app-box-app-btn-warning-color,	$app-box-app-btn-warning-background,	$app-box-hover-shadow-color,	$pending-badge-background,	$btn-outline-blue-color,	$btn-outline-blue-border-color,	$bgc-white-background-color ,	$text-green-d1-color ,	$text-purple-d1-color ,	$text-primary-d1-color ,	$text-secondary-d1-color ,	$text-danger-m3-color ,	$text-dark-l1-color ,	$app-dashboard-xpanel-background,	$drawer-background,	$edit-button-list-color,	$edit-button-list-border-color,	$drawer-li-hover-background,	$app-actions-clone-button-hover ,	$app-actions-edit-button-hover);
}

// all common imports;

@mixin ScheduleListView($schedule-list-day-color,$schedule-list-heading-color,$webkit-scrollbar-background-dark,$webkit-scrollbar-background-light) {
  .minimumHeight {
    min-height: 100px;
  }
  
  .scheduleContainer {
    .schedule {
      height: 52px;
      scroll-snap-align: start;
  
      .scheduleDateArea {
        width: 52px;
        margin-right: 10px;
        border-radius: 10px;
        padding: 5px;
  
        .day {
          color: $schedule-list-day-color;
          font-size: 24px;
          margin: 0;
          line-height: 24px;
          font-weight: bold;
          font-size: 18px;
        }
        .month {
          font-size: 13px;
          line-height: 1.4;
        }
      }
      .scheduleTextData {
        width: calc(100% - 52px);
        .title {
          font-weight: bold;
          color: $schedule-list-heading-color;
          font-size: 14px;
          line-height: 1.2;
        }
  
        .description {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.3;
          font-size: 13px;
        }
      }
    }
  }
  
  .scheduleContainerHeight {
    max-height: 590px;
    overflow-y: auto;
  }
  
  .scheduleContainerHeight::-webkit-scrollbar {
    width: 6px;
    transition: 0.3s;
  }
  
  .scheduleContainerHeight::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-dark;
    transition: 0.3s;
  }
  
  .scheduleContainerHeight::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-light;
    transition: 0.3s;
  }
  
  .scheduleContainerHeight:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .scheduleContainerHeight:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .scheduleContainerHeight:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include ScheduleListView($schedule-list-day-color,$schedule-list-heading-color,$webkit-scrollbar-background-dark,$webkit-scrollbar-background-light);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include ScheduleListView($schedule-list-day-color,$schedule-list-heading-color,$webkit-scrollbar-background-dark,$webkit-scrollbar-background-light);
}
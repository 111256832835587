// all common imports;

@mixin Payment($btn-render-background,$btn-render-color) {
  .dropin-parent {
    max-width: 420px;
    margin: auto;
    margin-top: 20px;
  }
  
  .order-token {
    font-weight: 600;
  }
  
  .inputText {
    width: 200px;
  }
  .btn-render {
    border-radius: 6px;
    background-color: $btn-render-background;
    color: $btn-render-color;
    border: none;
    font-size: 14px;
    padding: 11px 16px;
  }
  
  .style-dropin {
    outline: 0;
    border-width: 0 0 1px;
    border-radius: 0px;
  }
  
  .style-dropin:focus {
    border-color: $btn-render-background;
  }
  
  .style-container {
    margin-bottom: 8px;
    text-align: center;
    font-family: "DM Sans", sans-serif;
  }
  
}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include Payment($btn-render-background,$btn-render-color);
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include Payment($btn-render-background,$btn-render-color);
}
// all common imports;

@mixin CardRender($summary-card-color-light,$summary-card-color-dark,$summary-card-color-medium,$summary-card-label-color,$summary-card-value-color) {
  .counterContainer {
    padding: 15px 32px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $summary-card-color-light !important;
    border-radius: 20px;
    padding: 14px !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    //box-shadow: rgba($summary-card-color-dark, 0.11) 0px 7px 10px 0px;
  
    .margin_bottom_30 {
      margin-bottom: 30px;
    }
  
    .couter_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      float: left;
      margin: 8px 0 8px;
    }
  
    .couter_icon > div {
      border-right: solid rgba($summary-card-color-light, 0.3) 1px;
      padding-right: 35px;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
    }
  
    .couter_icon svg {
      font-size: 50px;
      line-height: normal;
      width: 100%;
      float: left;
      text-align: center;
    }
  
    .counter_no {
      display: flex;
      padding: 0 12px;
      align-content: center;
      align-items: center;
      width: 100%;
    }
  
    .counter_no p.total_no {
      margin: 0;
      font-size: 34px;
      float: left;
      width: 100%;
      line-height: normal;
      font-weight: 600;
      color: $summary-card-color-dark;
    }
  
    .head_couter {
      width: 100%;
      color: $summary-card-color-medium;
      font-weight: 600;
      margin: 0px 0 0 0;
      font-size: 16px;
    }
  
    .displayFields {
      flex-grow: 1;
      margin-top: 10px; 
      .label {
        text-align: end;
        font-size: 14px;
        color: $summary-card-label-color;
        line-height: 1;
      }
      .value {
        text-align: end;
        font-size: 19px;
        font-weight: 600;
        color: $summary-card-value-color;
        line-height: 1.7;
        margin-bottom: 8px;
      }
    }
  }
  
  .reload-button-card{
    position: absolute;
    top: 10px !important;
    right: 10px !important;
  }
  
  .card-details:hover {
    .reload-button{
      visibility: visible;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1399px) {
    .couter_icon > div {
      padding-right: 0;
    }
    .counter_no .head_couter {
      font-size: 16px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1399px) {
    .couter_icon > div {
      padding-right: 0;
    }
    .counter_no .head_couter {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .reload-button{
      visibility: visible;
    }
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include CardRender($summary-card-color-light,$summary-card-color-dark,$summary-card-color-medium,$summary-card-label-color,$summary-card-value-color);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include CardRender($summary-card-color-light,$summary-card-color-dark,$summary-card-color-medium,$summary-card-label-color,$summary-card-value-color);
}
// all common imports;

@mixin WishList($wishlist-card-background,$wishlist-card-border,$wishlist-card-tooltip-background) {
  .wishlist-card {
    border: none;
    background: $wishlist-card-background;
    border: 1px solid $wishlist-card-border;
    font-weight: bold;
    .actions {
      i {
        transition: 0.1s all ease-in-out;
        font-size: 18px;
        position: relative;
      }
      .tooltip {
        left: -8px;
        opacity: 1;
        bottom: -35px;
        .arrow {
          display: none;
        }
        .tooltip-inner {
          display: block;
          background-color: $wishlist-card-tooltip-background;
          width: 100px;
        }
      }
    }
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include WishList($wishlist-card-background,$wishlist-card-border,$wishlist-card-tooltip-background);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include WishList($wishlist-card-background,$wishlist-card-border,$wishlist-card-tooltip-background);
}
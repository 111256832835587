// all common imports;

@mixin StandardLayout($nav-title-border-color) {
  .standard_main_div {
    overflow-y: scroll;
    height: 100vh;
    overflow-x: hidden;
  }
  
  .nav-sm .nav.side-menu li a {
    padding: 14px 5px;
    text-align: center !important;
  }
  
  .standard_menu_section > ul {
    margin-top: 0;
  }
  
  .nav_menu {
    height: 60px;
  }
  
  .nav_title {
    height: 60px;
    //border-bottom: 1px solid $nav-title-border-color;
  }
  
  .standard_nav.standard_side_menu > li > a {
    margin-bottom: 0;
  }
  
  .standard-navbar-nav {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .standard-app-logo-img-sidemenu {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  
  .sidemenu-li:hover {
    cursor: pointer;
  }
  
  .standard_side_menu.standard_menu_section,
  .standard_main_div::-webkit-scrollbar {
    display: none;
  }
  
  .standard_side_menu.standard_menu_section {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .allAppsStandardLayout {
    height: 60px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include StandardLayout($nav-title-border-color);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include StandardLayout($nav-title-border-color);
}
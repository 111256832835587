.singleSchedule {
  display: flex;
  flex-wrap: wrap;
  border: 1.5px solid rgb(222, 222, 222);
  border-radius: 3px;
  padding: 10px;
  background: white;
  .userContainer {
    width: 35%;
    display: flex;
    align-items: center;
    .userImageContainer {
      height: 50px;
      width: 50px;
      .userImageDefault {
        width: 50px;
        height: 50px;
      }
      .userImage {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .userDataContainer {
      display: flex;
      align-items: center;
      .userData {
        margin: 0px 10px;
        .userName span {
          font-weight: 600;
          color: rgb(73, 73, 73);
          font-family: "DM Sans", sans-serif;
          white-space: nowrap;
        }
        .userDescription span {
          white-space: nowrap;
        }
      }
    }
  }

  .dataListContainer {
    width: 45%;
    display: flex;
    align-items: center;
    .dataList {
      width: 100%;
      margin: 0px 10px;
      .seperateLeftRight {
        width: 100%;
        display: flex;
        .leftText {
          width: 50%;
          white-space: nowrap;
          font-weight: 600;
          color: #494949;
        }
        .rightText {
          width: 50%;
          white-space: nowrap;
        }
      }
    }
  }

  .timeContainer {
    width: 20%;
    display: flex;
    justify-content: end;
    align-items: center;
    .time {
      .time1 {
        text-align: end;
        font-weight: 600;
        color: rgb(73, 73, 73);
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 768px) {
  .singleSchedule {
    .userContainer {
      width: 60%;
    }

    .dataListContainer {
      width: 100%;
      order: 1;
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid rgb(222, 222, 222);
    }
    .timeContainer {
      width: 40%;
    }
  }
}

@media (max-width: 500px) {
  .singleSchedule {
    .dataListContainer {
      .dataList {
        width: 100%;
        margin: 0px 10px;
        .seperateLeftRight {
          width: 100%;
          display: block;
          margin-bottom: 7px;
          .leftText {
            width: 100%;
            white-space: nowrap;
            font-weight: 600;
            color: #494949;
          }
          .rightText {
            width: 100%;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

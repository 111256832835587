// all common imports;

@mixin SideMenu($nav-bar-background,$sub-menu-background,$sub-menu-background-light,$nav-child-menu-background) {
  
  #root {
    $logoBackground : $nav-bar-background;
  }
  
  .navbar {
    background: var(--logoBackground);
    .fc-logo {
      width: 150px;
      height: 100%;
      margin: 0 auto;
      img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
      }
    }
  }
  
  .submenu {
    overflow-y: auto;
  }
  
  .submenu::-webkit-scrollbar {
    width: 5px;
  }
  
  .submenu::-webkit-scrollbar-track {
    background-color: $sub-menu-background;
    border-radius: 100px;
  }
  
  .submenu::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: $sub-menu-background-light;
  }
  
  .child_menu {
    display: none;
    padding: 10px 5px;
  }
  
  .side-menu:hover {
    .child_menu {
      display: block;
      animation: fade-in 0.6s;
    }
  }
  
  .side-menu:not(:hover) .child_menu.did-fade-in {
    display: block;
    animation: fade-out 0.6s;
  }
  
  .sub_child_menu {
    animation: slide-down 0.8s;
    .sub_childs {
      width: 90%;
    }
  }
  
  .nav.child_menu li div:hover,
  .nav.child_menu li div.active {
    background-color: $nav-child-menu-background;
    flex-grow: 1;
    border-radius: 10px;
  }
  
  .sidemenuIcon {
    font-size: 20px !important;
  }
  
  .sidemenuTitle {
    font-size: 11px !important;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slide-up {
    0% {
      opacity: 0;
      transform: translateY(10);
    }
    100% {
      opacity: 1;
      transform: translateY(10%);
    }
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include SideMenu($nav-bar-background,$sub-menu-background,$sub-menu-background-light,$nav-child-menu-background);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include SideMenu($nav-bar-background,$sub-menu-background,$sub-menu-background-light,$nav-child-menu-background);
}
/*********************************************/
/*        CUSTOMIZED DEFAULTS - START        */
/*********************************************/
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap/scss/_functions.scss";
@import "../../node_modules/bootstrap/scss/_variables.scss";
@import "./variable.scss";

#root {
  $background : $left-menubar-background;
  $bodyBackground : $body-background;
  $buttonBackground : $success;
  $chooseFileButtonBackground : $primary;
  $addToCartButtonBackground : $primary;
  $importButtonBackground : $background-color-black;
  $exportButtonBackground : $primary;
  $backButtonBackground : $secondary;
  $addButtonBackground : $secondary;
  $cancelButtonBackground : $danger;
  $searchButtonBackground : $secondary;
  $testLogicButtonBackground : $primary;
  $uninstallButtonBackground : $danger;
  $installButtonBackground : $success;
  $appBoxBackground : $app-box-background;
  $navMenuBackground: $nav-menu-background;
  $viewWishListButtonBackground: $blue;
  $updateFormTitleBackground: $view-background;
  $closeButtonBackground: $light;
  $formBorderColor: $border-color-default;
  $inputFieldBackgroundColor: $background-color-white;
  $inputFieldTextColor: $font-color-dark;
  $inputFieldBorderColor: $border-color-light;
  $tableBackgroundColor:$background-color-white;
  $dropdownTextColor: $font-color-black;
  $dropdownBorderColor: $border-color-light;
  $dropdownBackgroundColor: $background-color-white;
  $dropdownListHoverColor: $background-color-blue;
  $boxTextColor : $font-color-dark; 
}

// offcanvas slide view
$offcanvas-horizontal-width: 70%;
@media (max-width: 768px) {
  .offcanvas {
    --bs-offcanvas-width: 100%;
  }
}

$body-bg: $body-background;
$body-color: rgba($body-default-color, 0.7);
$font-family-sans-serif: "DM Sans", sans-serif;
$enable-dark-mode: false;

// Start - Overriding Boostrap default classes

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

// Style anchor elements.
$link-color: $primary !default;
$link-decoration: underline !default;
$link-shade-percentage: 20% !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: null !default;

// $font-size-base affects the font size of the body text
$font-size-root: null !default;
$font-size-base: 0.875rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// End - Overriding Boostrap default classes

@import "../../node_modules/react-confirm-alert/src/react-confirm-alert.css";
@import "antd/dist/antd.css";
@import "../../node_modules/react-toastify/dist/ReactToastify.css";
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import "../styles/variable.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
@import "../../node_modules/react-big-calendar/lib/sass/styles.scss";

body {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  color: rgba($body-default-color, 0.85);
}

.Card div {
  background-color: $card-background;
  border-radius: 5px;
  margin-bottom: 15px;
}

.chooseFile-custom-btn{
  background: var(--chooseFileButtonBackground);
  border: 1px solid;
  border-color: var(--chooseFileButtonBackground);
  color: $font-color-white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 18px;
}

.custom-btn{
  background: var(--buttonBackground);
  border: 1px solid;
  border-color: var(--buttonBackground);
  color: $font-color-white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 18px;
}

.addToCart-btn{
  background: var(--addToCartButtonBackground);
  border: 1px solid;
  border-color: var(--addToCartButtonBackground);
  color: $font-color-white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 18px;
}

.viewWishList-btn{
  background: var(--viewWishListButtonBackground);
  border: 1px solid;
  border-color: var(--viewWishListButtonBackground);
  color: $font-color-white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 18px;
}

.import-btn{
  background: var(--importButtonBackground);
  border: 1px solid;
  border-color: var(--importButtonBackground);
  color: $font-color-white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 1rem;
}

.export-btn{
  background: var(--exportButtonBackground);
  border: 1px solid;
  border-color: var(--exportButtonBackground);
  color: $font-color-white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 1rem;
}

.back-btn{
  background: var(--backButtonBackground);
  border: 1px solid;
  border-color: var(--backButtonBackground);
  color: $font-color-white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 1rem;
}

.add-btn{
  background: var(--addButtonBackground);
  border: 1px solid;
  border-color: var(--addButtonBackground);
  color: $font-color-white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 1rem;
}

.cancel-btn{
  background: var(--cancelButtonBackground);
  border: 1px solid;
  border-color: var(--cancelButtonBackground);
  color: $font-color-white;
  padding: 6.3px 10px;
  border-radius: 6px;
  font-size: 1rem;
}

.close-btn{
  background: var(--closeButtonBackground);
  border: 1px solid;
  border-color: var(--closeButtonBackground);
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 1rem;
}

.search-btn{
  background: var(--searchButtonBackground);
  border: 1px solid;
  border-color: var(--searchButtonBackground);
  color: $font-color-white;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 1rem;
}

.testLogic-btn{
  background: var(--testLogicButtonBackground);
  border: 1px solid;
  border-color: var(--testLogicButtonBackground);
  color: $font-color-white;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 1rem;
}

.uninstall-btn{
  background: var(--uninstallButtonBackground);
  border: 1px solid;
  border-color: var(--uninstallButtonBackground);
  color: $font-color-white;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 1rem;
}

.install-btn{
  background: var(--installButtonBackground);
  border: 1px solid;
  border-color: var(--installButtonBackground);
  color: $font-color-white;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 1rem;
}

.left_col {
  background: var(--background);
  position: fixed;
  z-index: 99;
}
.nav-sm .body .left_col {
  min-height: 100%;
  width: 70px;
  padding: 0;
  position: absolute;
}

.nav-sm .body .right_col {
  margin-left: 70px;
  z-index: 2;
  background: var(--bodyBackground);
}

.nav-sm .navbar.nav_title {
  width: 70px;
}
.nav-sm .navbar.nav_title a span {
  display: none;
}
.nav-sm .navbar.nav_title a i {
  font-size: 27px;
  margin: 13px 0 0 3px;
}
.nav-sm ul.nav.child_menu ul,
.nav-sm .nav.side-menu li.active-sm ul ul {
  position: static;
  width: 200px;
  background: none;
}
.nav-sm > .nav.side-menu > li.active-sm > a {
  color: $nav-side-menu-color !important;
}
.nav-sm ul.nav.child_menu {
  left: 100%;
  position: absolute;
  top: 0;
  width: 210px;
  z-index: 4000;
  background: var(--background);
}
.nav-sm ul.nav.child_menu li {
  padding: 0 10px;
}
.nav-sm ul.nav.child_menu li a {
  text-align: left !important;
}
.nav-sm .profile {
  display: none;
}
.menu_section {
  margin-bottom: 35px;
}
.menu_section h3 {
  padding-left: 23px;
  color: $menu-section-h3;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 11px;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: 1px 1px $menu-section-h3-text-shadow;
}
.menu_section > ul {
  display: block;
}

.img-circle.profile_img {
  width: 70%;
  background: $profile-img-background;
  margin-left: 15%;
  z-index: 1000;
  position: inherit;
  margin-top: 20px;
  border: 1px solid $profile-img-border;
  padding: 4px;
}

.main_menu span.fa {
  float: right;
  text-align: center;
  margin-top: 5px;
  font-size: 10px;
  min-width: inherit;
  color: $main_menu-icon-color;
}
.active a span.fa {
  text-align: right !important;
  margin-right: 4px;
}
.nav-sm .menu_section {
  margin: 0;
}
.nav-sm .side-menu span.fa,
.nav-sm .menu_section h3 {
  display: none;
}
.nav-sm li li span.fa {
  display: inline-block;
}
.nav_menu {
  float: left;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: fixed;
  top: 0px;
  left: auto;
  right: 0px;
  color: $nav-menu-color;
  padding-left: 0px;
  background-color: var(--navMenuBackground);
  //border-bottom: 1px solid $nav-menu-border;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}

.nav-md .body .left_col {
  min-height: 100%;
  width: 0px;
  padding: 0;
  position: absolute;
  display: flex;
  z-index: 1;
  overflow: hidden;
}
body .body .right_col {
  background: var(--bodyBackground);
}
.rightMainContainer {
  background: var(--bodyBackground);
}
.nav-md .body .right_col {
  margin-left: 0px;
}
.nav_title {
  width: 70px;
  float: left;
  background: $nav-title-background;
  border-radius: 0;
  height: 57px;
  padding: 0px;
}

.blue {
  color: $font-color-blue;
}
.purple {
  color: $font-color-purple;
}
.green {
  color: $font-color-green;
}

.red {
  color: $font-color-red;
}
.dark {
  color: $font-color-dark;
}

.border-purple {
  border-color: $border-color-purple !important;
}
.border-green {
  border-color: $border-color-green !important;
}

.border-red {
  border-color: $border-color-red !important;
}
.border-dark {
  border-color: $border-color-dark !important;
}
.bg-green {
  background: $background-color-green !important;
  border: 1px solid $border-color-green !important;
  color: $font-color-white;
}
.bg-red {
  background: $background-color-red !important;
  border: 1px solid $border-color-red !important;
  color: $font-color-white;
}
.bg-blue {
  background: $background-color-blue !important;
  border: 1px solid $border-color-blue !important;
  color: $font-color-white;
}

.darkBlue {
  background: $nav-title-background;
  overflow: hidden;
}

.bg-white {
  background: var(--tableBackgroundColor) !important;
}
.border,
.border-start,
.border-bottom {
  border-color: var(--formBorderColor) !important;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  color: $heading-color-dark !important;
}
p,
label {
  color: var(--boxTextColor) !important;
}

.form-control {
  background-color: var(--inputFieldBackgroundColor) !important;
  color: var(--inputFieldTextColor) !important;
  border-color: var(--inputFieldBorderColor) !important;
}
.form-control:disabled{
  background-color: $background-color-light !important;
  color: $font-color-dark !important;
}

.bodyBackground {
  background: $left-menubar-background;
  overflow: hidden;
}

.navbar-nav > li > a,
.navbar-nav > li > a {
  color: $font-color-white !important;
}

.main_content {
  padding: 10px 20px;
}

.navbar {
  margin-bottom: 0;
}
.navbar-header {
  background: $nav-title-background;
}
.navbar-right {
  margin-right: 0;
}
.top_nav .navbar-right {
  margin: 13px;
  width: auto;
  float: right;
}
.top_nav .navbar-right li {
  display: block;
  float: right;
  position: static;
}

.top_nav .dropdown-menu li {
  width: 100%;
}
.top_nav li a i {
  font-size: 15px;
}
.navbar-static-top {
  position: fixed;
  top: 0;
  width: 100%;
}

.nav > li {
  position: relative;
  display: block;
}
.nav.child_menu {
  display: none;
}
.nav.child_menu li > a:hover,
.nav.child_menu li > a.active {
  background-color: rgba($background-color-white, 0.06);
  flex-grow: 1;
  border-radius: 10px;
}
.nav.child_menu li {
  padding-left: 36px;
}
.nav-md ul.nav.child_menu li:before {
  background: $background-color-dark-700;
  bottom: auto;
  content: "";
  height: 8px;
  left: 23px;
  margin-top: 15px;
  position: absolute;
  right: auto;
  width: 8px;
  z-index: 1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.nav-md ul.nav.child_menu li:after {
  border-left: 1px solid $background-color-dark-700;
  bottom: 0;
  content: "";
  left: 27px;
  position: absolute;
  top: 0;
}
.nav-md ul.nav.child_menu li:last-child::after {
  bottom: 50%;
}
.nav.side-menu > li > a,
.nav.child_menu > li > a {
  color: $font-color-white;
}
.nav.child_menu li li:hover,
.nav.child_menu li li.active {
  background: none;
}
.nav.child_menu li li a:hover,
.nav.child_menu li li a.active {
  color: $font-color-white;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 13px 15px 12px;
}
.nav.side-menu > li.current-page,
.nav.side-menu > li.active {
  border-right: 5px solid $nav-side-menu-link-active;
}
.nav li.current-page {
  background: rgba($background-color-white, 0.05);
}
.nav li li li.current-page {
  background: none;
}
.nav li li.current-page a {
  color: $font-color-white;
}
.nav.side-menu > li.active > a {
  text-shadow: rgba($background-color-black, 0.25) 0 -1px 0;
  background: -webkit-gradient(
      linear,
      50% 0%,
      50% 100%,
      color-stop(0%, $nav-webkit-gradient-color1),
      color-stop(100%, $nav-webkit-gradient-color2)
    ),
    $nav-webkit-gradient-color1;
  background: -webkit-linear-gradient(
      $nav-webkit-gradient-color3,
      $nav-webkit-gradient-color4
    ),
    $nav-title-background;
  background: -moz-linear-gradient(
      $nav-webkit-gradient-color3,
      $nav-webkit-gradient-color4
    ),
    $nav-title-background;
  background: -o-linear-gradient(
      $nav-webkit-gradient-color3,
      $nav-webkit-gradient-color4
    ),
    $nav-title-background;
  background: linear-gradient(
      $nav-webkit-gradient-color3,
      $nav-webkit-gradient-color4
    ),
    $nav-title-background;
  -webkit-box-shadow: rgba($background-color-black, 0.25) 0 1px 0,
    inset rgba($background-color-white, 0.16) 0 1px 0;
  -moz-box-shadow: rgba($background-color-black, 0.25) 0 1px 0,
    inset rgba($background-color-white, 0.16) 0 1px 0;
  box-shadow: rgba($background-color-black, 0.25) 0 1px 0,
    inset rgba($background-color-white, 0.16) 0 1px 0;
}
.navbar-nav > li > a {
  color: $navbar-nav-link-color !important;
  margin-left: 0 !important;
  line-height: 32px;
}
.nav.navbar-nav > li > a {
  color: $nav-navbar-nav-link-color !important;
}
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
}
.toggle {
  float: left;
  margin: 0;
}
.toggle a {
  margin: 15px 15px;
  cursor: pointer;
}
.toggle a svg {
  font-size: 26px;
}
.panel_toolbox > a:not(:last-child) {
  float: left;
  cursor: pointer;
}
.panel_toolbox > a {
  padding: 10px;
  color: $panel-toolbox-link-color;
  font-size: 14px;
}

.table {
  font-size: 14px;
}

span.right {
  float: right;
  font-size: 14px !important;
}

/* *********  icons-display  **************************** */

.main_menu .fa {
  width: 26px;
  opacity: 0.99;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn,
.modal-footer .btn + .btn {
  margin-bottom: 5px;
  margin-right: 5px;
}

/* *********  form design  **************************** */
span.section {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: $span-section-color;
  border: 0;
  border-bottom: 1px solid $span-section-border;
}
.form-horizontal .control-label {
  padding-top: 8px;
}
.form-control:focus {
  box-shadow: none !important;
}

.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}
.form-group {
  margin-bottom: 10px;
}

/* *********  ecommerce  **************************** */

.price {
  font-size: 20px;
  font-weight: 400;
  color: $ecommerce-price;
  margin: 0;
}
.product-image img {
  width: 90%;
}
.clearfix {
  border-bottom: 0.5px solid var(--formBorderColor);
}

.x_panel {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
  background: $ecommerce-xpanel-background;
  border: 1px solid $ecommerce-xpanel-border;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid-column;
  opacity: 1;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.x_content {
  padding: 0 3px 6px;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  margin-top: 5px;
}

// helper css
.d-help {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.right_col {
  min-height: 100vh;
  background: var(--bodyBackground);
}

.pointer {
  cursor: pointer;
}

.font-16 {
  font-size: 16px;
}

.text-sm {
  font-size: $font-12;
}

.text-dark {
  color: $font-color-dark !important;
}

.custom-tag {
  padding: 5px;
  background: $ecommerce-custom-tag-background;
  margin: 0px;
  color: $ecommerce-custom-tag-color;
  font-weight: bold;
  font-size: 10px;
  margin-right: 15px;
  border-radius: 5px;
}

.background-border {
  padding: 15px;
  border: 1px solid;
  margin-top: 2rem;
  border-color: $ecommerce-custom-tag-background;
}

.product-map-modal {
  .modal-dialog {
    max-width: 80% !important;
    .modal-content {
      border-radius: 0px;
      .modal-body {
        padding: 5px;
        .image-gallery-svg,
        .image-gallery-svg {
          height: 60px !important;
        }
        .image-gallery-slide {
          height: 550px;
          img.image-gallery-image {
            width: 100%;
            max-height: none;
            height: 100%;
            object-fit: scale-down;
          }
        }
      }
    }
  }
}

.cursorNotAllowed {
  cursor: not-allowed !important;
}

@media screen and (max-width: 600px) {
  .product-map-modal .modal-dialog {
    max-width: 100% !important;
  }
  .modal-title.h4 {
    font-weight: bold;
    font-size: 16px;
  }
}

.form-check-input{
  height: 17px;
  width: 17px;
}

.checkboxHeight {
  height: 17px;
  width: 17px;
}

.checkboxtContainer {
  height: 100%;
}

.disclaimerText {
  font-size: 14px;
}

.sharedHeight {
  min-height: 100vh;
}

.tableTheme1 table {
  border-spacing: 0 !important;
  border-collapse: initial;
}

.tableTheme1 th {
  position: sticky;
  top: 0;
  padding: 16px;
  margin: 0px;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: "DM Sans", sans-serif;
}

.tableTheme1 td,
.tableTheme1 tbody td {
  padding: 16px;
  margin: 0px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: "DM Sans", sans-serif;
  color: $table-theme1-color;
}

.tableTheme1 th[action="true"]:last-child {
  z-index: 11;
  position: sticky;
  right: 0px;
  border-left: 1.5px solid $table-theme1-border-left !important;
  border-right: 1px solid $table-theme1-border-right;
}

.tableTheme1 th[checkBox="true"]:first-child,
.tableTheme1 td[checkBox="true"]:first-child {
  text-align: center;
}

.tableTheme1 td[action="true"]:last-child {
  position: sticky;
  right: 0px;
  border-left: 1.5px solid $table-theme1-border-left !important;
}

.tableTheme1 th:last-child,
.tableTheme1 th:last-child {
  z-index: 2;
}

.theme1 .table td,
.theme1 .table th {
  border-bottom-width: 0px;
  padding: 16px;
  margin: 0pxpx;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: "DM Sans", sans-serif;
  //box-shadow: $table-td-group-background 0px 7px 10px 0px;
}

.theme1 .table th {
  z-index: 9;
  border-bottom-width: 0px;
  padding: 16px;
  margin: 0pxpx;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: "DM Sans", sans-serif;
  //box-shadow: $table-td-group-background 0px 7px 10px 0px;
}

.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  background-color: $gutter-background;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
  cursor: col-resize;
  transition: 0.3s;
}

.gutter.gutter-horizontal:hover,
.gutter.gutter-horizontal:active {
  box-shadow: 0px 3px 6px 2px rgba($gutter-shadow-active, 0.13);
  background-color: $gutter-background-active;
}

.formViewWidth {
  transition: width 0.5s ease-in-out;
  overflow: hidden;
}
.viewHeight {
  max-height: 570px;
  overflow-y: auto;
  overflow-x: hidden;
}

.viewTitleHeader {
  background: #f8b425;
  position: sticky;
  top: 0;
  z-index: 1;
  left: 0;
  right: 0;
}

.viewBackground {
  background-color: var(--updateFormTitleBackground);
}

.reset-table-body-height {
  min-height: 91vh;
}

.ellipsis-button .dropdown-toggle::after {
  display: none;
}

@media only screen and (max-width: 768px) {
  .tableTheme1 th[action="true"]:last-child {
    position: initial;
    border-left: 1.5px solid $table-theme1-border-left;
  }

  .tableTheme1 td[action="true"]:last-child {
    position: initial;
    border-left: 1.5px solid $table-theme1-border-left;
  }

  .gutter.gutter-horizontal {
    width: 0px !important;
    margin: 0px;
  }
  .formViewWidth {
    transition: height 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55),
      width 0.5s ease-in-out;
  }
  .tableClose {
    width: 0% !important;
    animation: decrease-height 1.5s forwards;
  }

  .formViewOpen {
    width: 100% !important;
    transition: height 0.3s ease-out;
  }

  .viewHeight {
    max-height: none;
  }

  @keyframes decrease-height {
    from {
      height: auto;
    }
    to {
      height: 50px;
    }
  }
}

.textOverFlowEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

a {
  text-decoration: none !important;
}

textarea {
  outline: none !important;
}

.toggleFormBtnSize {
  font-size: 27px !important;
}

.toggleTableBtnSize {
  font-size: 25px !important;
}

.center-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $center-loader-background;
  z-index: 55;
}

button[actionbutton="true"] {
  transition: 0.2s all;
}

button[actionbutton="true"]:hover {
  opacity: 0.7;
}

.formFieldBg {
  background: $form-field-background;
}

.sectionInForm {
  color: $section-in-form-color !important;
  background-color: $section-in-form-background !important;
}

.scrollToTop {
  color: $scroll-to-top-color;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $scroll-to-top-background;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

.show {
  opacity: 1;
  visibility: visible;
}

select.form-select {
  font-size: 14px;
}

.border-radius-large {
  border-radius: 20px !important;
}
.login-background {
  background-color: $login-main-background;
}

.highcharts-background {
  fill: $background-color-white;
}

.searchableDropdownMenuPortal div,
.searchableDropdownControl,
.searchableDropdownValue {
  color: var(--dropdownTextColor) !important;
  border-color: var(--dropdownBorderColor) !important;
  font-size: 12px !important;
  background-color: var(--dropdownBackgroundColor) !important
}
.searchableDropdownMenuList *:hover {
  background-color: var(--dropdownListHoverColor) !important;
}

.react-select__control {
  min-height: 30px !important;
  font-size: 12px !important;
}
.react-select__indicator {
  padding: 3px !important;
}

.react-confirm-alert-button-group > button{
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.react-confirm-alert-button-group{
  justify-content: flex-end !important;
  border-top: 2px solid $border-color-default;
  padding-top: 10px !important;
}

.react-confirm-alert-body{
  border-left: 3px solid #f8b425 !important;
}
// all common imports;

@mixin LineItems($webkit-scrollbar-background-light,$webkit-scrollbar-background-dark) {
  .line-items-wrapper {
    .form-group {
      margin-bottom: 5px !important;
    }
    .form_wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
  
      .action-button {
        width: 20% !important;
      }
      .action-wrapper,
      .action-button {
        margin: 0px;
        display: flex;
        align-items: center;
        margin-top: 20px;
        width: 10%;
      }
    }
    .form-group.field.field-string,
    .form-group.field.field-object .form-group.field.field-object,
    .form-group.field.field-number,
    .form-group.field.field-array {
      width: 32%;
      margin-right: 1%;
      margin-bottom: 5px;
    }
    fieldset#root {
      justify-content: flex-start;
    }
    fieldset > div > fieldset > div {
      width: 100% !important;
    }
  }
  .text-underline {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .scrollHeightLineItem {
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    max-height: 250px;
  }
  
  .scrollHeightLineItem::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  .scrollHeightLineItem::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
    border-radius: 10px;
  }
  
  .scrollHeightLineItem::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $webkit-scrollbar-background-dark;
    cursor: pointer;
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include LineItems($webkit-scrollbar-background-light,$webkit-scrollbar-background-dark);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include LineItems($webkit-scrollbar-background-light,$webkit-scrollbar-background-dark);
}
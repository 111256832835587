// all common imports;

@mixin RowLineEdit() {
    .table_column {
        max-width: 400px !important;
    }
  }
  
  html.default {
    @import "../../../../styles/themes/theme-default.scss";
    @import "../../../../styles/theme-color-variables.scss";
    @include RowLineEdit();
  }
  html.dark {
    @import "../../../../styles/themes/theme-dark.scss";
    @import "../../../../styles/theme-color-variables.scss";
    @include RowLineEdit();
  }
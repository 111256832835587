// all common imports;

@mixin FilterForm($filter-form-heading-color,$webkit-scrollbar-background-light,$webkit-scrollbar-background-dark,$yellow,$green,$white,$black,$gray) {
  .filterFormContainer {
    display: flex !important;
    justify-content: start !important;
    height: 80vh;
  }
  
  .filterForm > .rjsf > .field > fieldset#root {
    display: flex;
    flex-wrap: wrap;
    justify-content: start !important;
    width: 100vw !important;
    margin-left: 10px;
  }
  
  .filterForm > .rjsf > .field > fieldset > .field {
    padding: 0 !important;
    width: 21.5% !important;
  }
  
  .filter-heading {
    color: $filter-form-heading-color;
  }
  
  .verticalFilterForm .form-group.field.field-string,
  .verticalFilterForm .form-group.field.field-object,
  .verticalFilterForm .form-group.field.field-number,
  .verticalFilterForm .form-group.field.field-array {
    padding: 0 !important;
    width: 100% !important;
  }
  
  .verticalFilterForm {
    height: 60vh !important;
    overflow-y: auto;
  }
  
  .filterFormHeight {
    height: 80vh !important;
  }
  
  .verticalFilterForm::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    transition: 0.3s;
  }
  
  .verticalFilterForm::-webkit-scrollbar-track {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .verticalFilterForm::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .verticalFilterForm:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .verticalFilterForm:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .verticalFilterForm:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .rightFilterForm::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    transition: 0.3s;
  }
  
  .rightFilterForm::-webkit-scrollbar-track {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .rightFilterForm::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .rightFilterForm:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .rightFilterForm:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .rightFilterForm:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .leftFilterForm::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    transition: 0.3s;
  }
  
  .leftFilterForm::-webkit-scrollbar-track {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .leftFilterForm::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .leftFilterForm:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .leftFilterForm:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .leftFilterForm:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .rightFilterForm {
    order: -1;
  }
  
  .overflowYAuto {
    overflow-y: auto;
  }

  .cursor{
    cursor: context-menu;
  }

  .stepper-name {
    cursor: pointer;
    font-size: 20px;
  }
  
  .active-stepper {
    font-weight: bold;
    color: $black;
  }
  
  .disabled-stepper {
    color: $gray;
  }   

  .button-container {
    display: flex;
    gap: 10px;
    position: relative;
    margin-top: 50px;
  }
  
  .buttons {
    padding: 10px 20px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .save-draft {
    background-color: $white;
    color: $black;
    border: 1px solid $gray;
  }
  
  .save-draft:hover {
    background-color: $black;
    color: $white;
    border: none;
  }
  
  .nextb {
    background-color: $black;
    color: $white;
    border: none;
  }
  
  .nextb:hover {
    background-color: $white;
    color: $black;
    border: 1px solid $gray;
  }
  
  .blink {
    animation: blinkEffect 0.3s ease;
  }

  .stepper-name {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
  }
  
  .stepper-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .stepper-circle.completed {
    border-color: $green;
    background-color: $green;
  }

  .stepper-circle.saved {
    border-color: $yellow; 
    background-color: $yellow;
  }
  
  .stepper-circle.active {
    border-color: $green; 
    background-color: $white;
  }
  
  .stepper-circle.inactive {
    border-color: $gray;
    background-color: $white;
  }
  
  .stepper-label {
    font-weight: 600;
    color: $black;
  }
  
  .stepper-label.inactive {
    color: $gray;
  }
  
  
  @keyframes blinkEffect {
    0%, 100% {
      background-color: $black;
      color: $white;
    }
    50% {
      background-color: $white; 
      color: $black;
    }
  }
  
  
  
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .filterForm > .rjsf > .field > fieldset > .field {
      padding: 0 !important;
      width: 20% !important;
    }
  }
  
  @media screen and (max-width: 768px) {
    .filterFormContainer {
      flex-direction: column;
      padding: 0 !important;
      height: auto;
    }
  
    .verticalFilterForm {
      height: auto !important;
      overflow: hidden !important;
    }
  
    .filterFormHeight {
      height: auto !important;
    }
  
    .filterForm > .rjsf > .field > fieldset > .field {
      width: 45% !important;
      margin: 0 5px;
    }
  
    .filterForm > .rjsf > .field > fieldset#root {
      width: 100% !important;
    }
  
    .rightFilterForm {
      order: 1;
      overflow-y: auto;
    }
  
    .verticalFilterForm > .rjsf > .field > fieldset > .field {
      width: 44% !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  
    .verticalFilterForm > .rjsf > .field > fieldset#root {
      width: 100% !important;
    }
  }
  
  @media screen and (max-width: 400px) {
    .verticalFilterForm > .rjsf > .field > fieldset > .field {
      width: 100% !important;
      padding: 0 !important;
    }
  
    .filterForm > .rjsf > .field > fieldset#root {
      width: 93% !important;
    }
  
    .filterForm > .rjsf > .field > fieldset > .field {
      width: 100% !important;
      margin: 0 5px;
    }
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include FilterForm($filter-form-heading-color,$webkit-scrollbar-background-light,$webkit-scrollbar-background-dark,$yellow,$green,$white,$black,$gray);
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include FilterForm($filter-form-heading-color,$webkit-scrollbar-background-light,$webkit-scrollbar-background-dark,$yellow,$green,$white,$black,$gray);
}

// all common imports;

@mixin Kanban($kanban-heading-color,	$webkit-scrollbar-background-dark,	$webkit-scrollbar-background-light,	$kanban-background,	$kanban-hover-background,	$kanban-card-label-border,	$kanban-card-action-dropdown-color,	$kanban-card-preview-background) {
  .kanban-board {
    border-radius: 4px;
    overflow-x: auto;
  }
  
  .kanban-board-heading {
    color: $kanban-heading-color;
  }
  
  .kanban-board::-webkit-scrollbar {
    height: 6px;
    transition: 0.3s;
  }
  
  .kanban-board::-webkit-scrollbar-track {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .kanban-board::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .kanban-board:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .kanban-board:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .kanban-board:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .kanban-board-container {
    min-height: 50px;
    overflow: auto;
  }
  
  .kanban-lane-header {
    font-size: 16px;
  }
  
  .kanban-card {
    background-color: $kanban-background;
    min-height: 60px;
  }
  
  .kanban-card:hover {
    background-color: $kanban-hover-background;
  }
  
  .kanban-card-title {
    font-size: 16px;
    word-wrap: break-word;
  }
  
  .kanban-card-description {
    font-size: 16px;
    word-wrap: break-word;
  }
  
  .kanban-card-label {
    font-size: 12px;
    width: 120px;
    padding: 1px 0 !important;
  }
  
  .kanban-card-label-border {
    border: 1px solid $kanban-card-label-border !important;
  }
  
  .cursorPointer {
    cursor: pointer;
  }
  
  .kanban-card-action-dropdown .dropdown-toggle {
    background-color: transparent !important;
    color: $kanban-card-action-dropdown-color !important;
    margin: 0 !important;
    border: none !important;
  }
  
  .kanban-card-action-dropdown .dropstart .dropdown-toggle::before {
    display: none !important;
  }
  
  .kanban-card-action-dropdown .btn-primary.dropdown-toggle:focus {
    border: none !important;
    box-shadow: none !important;
  }
  
  .kanban-card-assignees {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .kanban-card-assignee,
  .kanban-card-reporter {
    height: 30px !important;
    width: 30px !important;
    display: inline-block;
  }
  
  .kanban-card-tags {
    font-size: 10px !important;
  }
  
  .kanban-card-assignee-name {
    margin-top: 2.5px;
  }
  
  .kanban-card-assignees::-webkit-scrollbar {
    height: 6px;
    transition: 0.3s;
  }
  
  .kanban-card-assignees::-webkit-scrollbar-track {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .kanban-card-assignees::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .kanban-card-assignees:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .kanban-card-assignees:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .kanban-card-assignees:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .kanban-card-label-detailed-view {
    width: 250px;
    font-size: 15px;
  }
  
  .kanban-card-preview {
    background-color: $kanban-card-preview-background;
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Kanban($kanban-heading-color,	$webkit-scrollbar-background-dark,	$webkit-scrollbar-background-light,	$kanban-background,	$kanban-hover-background,	$kanban-card-label-border,	$kanban-card-action-dropdown-color,	$kanban-card-preview-background);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Kanban($kanban-heading-color,	$webkit-scrollbar-background-dark,	$webkit-scrollbar-background-light,	$kanban-background,	$kanban-hover-background,	$kanban-card-label-border,	$kanban-card-action-dropdown-color,	$kanban-card-preview-background);
}
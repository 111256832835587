@mixin AddUser($border-color) {
  .singleCart {
    .quantity-wrapper {
      min-height: 32px;
      width: 122px;
      position: relative;
    }
    .counter {
      width: 30px;
      height: 32px;
    }
    .counterInput {
      width: 60px;
      border: none;
      text-align: center;
      background: none;
    }
    .counterInput:focus {
      outline: none;
    }

    .counterInput::-webkit-inner-spin-button,
    .counterInput::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    .counter span {
      font-size: 20px;
    }
    .cartView {
      img {
        max-width: 50px;
      }
    }

    .progressIndeterminateContainer {
      width: 100%;
      height: 2px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
    }

    .progressBar {
      width: 100%;
      height: 100%;
      background-color: $border-color;
      animation: indeterminateAnimation 1s linear infinite;
    }

    @keyframes indeterminateAnimation {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }
  }

  .opacity-5 {
    opacity: 0.5;
  }
}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include AddUser($border-color-blue);
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include AddUser($blue-gray-400);
}

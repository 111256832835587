.progress-container{
    display: flex;
    width: 100%;
  }
  .subscription-card{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 110px;
    border-left:5px solid #2a3f54;
  
    }
  
  .subscription-card:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    transition: 0.5s ease-in;
  }
 .progress-container  .progress{
    margin-top: 10px;
    height: 22px !important;
    width: 80% !important;
    
   }
   .storage{
    margin-top: 12px;
    font-weight: 700;
  }
   .card-data h2{
    font-weight: 600;
    font-size: 22px;
    color: #1abb9c;
   }
   .card-icon{
    color: #73879c;
   }
  
   .rupes_icon{
    color: #73879c;
   }
// all common imports;

@mixin Roles($roles-background,$dataset-table-border) {
  .role_height {
    height: 115vh;
    background-color: $roles-background;
  }
  
  .saved-role > .table-responsive > .table-bordered {
    border: 1px solid $dataset-table-border !important;
  }
  
  .saved-role .table > :not(caption) > * > * {
    padding: 15px 0.5rem !important;
  }
  
}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include Roles($roles-background,$dataset-table-border);
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include Roles($roles-background,$dataset-table-border);
}
// all common imports;

@mixin Schedule() {
    .max_width {
      max-width: 400px !important;
    }
  }
  
  html.default {
    @import "../../../styles/themes/theme-default.scss";
    @import "../../../styles/theme-color-variables.scss";
    @include Schedule();
  }
  html.dark {
    @import "../../../styles/themes/theme-dark.scss";
    @import "../../../styles/theme-color-variables.scss";
    @include Schedule();
  }
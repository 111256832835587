// all common imports;

@mixin SortData($sort-app-characters-list-border,$sort-app-fa-close-border,$sort-app-fa-close-hover-border,$height-scroll-webkit-scrollbar-track-background, $height-scroll-webkit-scrollbar-thumb-background) {
  .sort_app {
    .characters {
      list-style: none;
      padding-left: 0;
    }
  
    .characters .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 1.5px $sort-app-characters-list-border;
      border-radius: 0.2em;
      padding: 6px 0.8em 0.5em 0.5em;
      margin-bottom: 0.5em;
    }
  
    .characters p {
      max-width: none;
      font-weight: bold;
      margin: 0;
    }
  
    .fa-close {
      cursor: pointer;
      height: 20px;
      width: 20px;
      text-align: center;
      border: 2px solid $sort-app-fa-close-border;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px;
    }
    .fa-close:hover {
      border-color: $sort-app-fa-close-hover-border;
    }
    .fa-edit {
      cursor: pointer;
    }
  }
  
  .scroll_height {
    max-height: 36vh;
    overflow: auto;
    scroll-behavior: smooth;
  }
  
  .scroll_height::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  .scroll_height::-webkit-scrollbar-track {
    background-color: $height-scroll-webkit-scrollbar-track-background;
    border-radius: 100px;
  }
  
  .scroll_height::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: $height-scroll-webkit-scrollbar-thumb-background;
  }
  
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include SortData($sort-app-characters-list-border,$sort-app-fa-close-border,$sort-app-fa-close-hover-border,$height-scroll-webkit-scrollbar-track-background, $height-scroll-webkit-scrollbar-thumb-background) ;
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include SortData($sort-app-characters-list-border,$sort-app-fa-close-border,$sort-app-fa-close-hover-border,$height-scroll-webkit-scrollbar-track-background, $height-scroll-webkit-scrollbar-thumb-background) ;
}
// all common imports;

@mixin App($app-label-required) {
  label.required::after {
    content: '*';
    margin-right: 4px;
    color: $app-label-required;
  }
}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include App($app-label-required);
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include App($app-label-required);
}

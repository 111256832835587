// all common imports;

@mixin AdminLogin($login-main-background,$login-main-input-border-color,$login-main-input-col-md-color-h2,$login-main-content-border-left,$login-main-content-control-label,$login-right-side-col-background,$login-main-button-background) {
  .login_main {
    background: $login-main-background;
  
    // height: 100vh;
    input {
      display: block;
      text-transform: none;
      width: 100%;
      height: 35px;
      border-width: 0 0 1px;
      border-style: solid;
      border-color: $login-main-input-border-color;
      font-weight: 400;
    }
    .col-md-12 {
      h2 {
        color: $login-main-input-col-md-color-h2;
      }
      .col-md-7 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .right-side-col {
        .login_main_content {
          .logo {
            height: 20%;
            width: 20%;
            margin-bottom: 2rem;
          }
          height: 90vh;
          flex-wrap: wrap;
          align-content: center;
          display: flex;
          border-left: 1px solid $login-main-content-border-left;
          padding-left: 2rem;
          .control-label {
            font-size: 16px;
            color: $login-main-content-control-label;
          }
        }
        height: 100vh;
        background: $login-right-side-col-background;
        padding: 2rem;
        .form_content {
          .logo-img {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            img {
              width: 25%;
              margin: 0 auto;
            }
          }
        }
      }
      .footer_title {
        p {
          color: $login-main-content-control-label;
          font-weight: bold;
          font-size: 12px;
          float: right;
        }
      }
      button {
        border: 0px;
        padding: 4px 15px;
        float: right;
      }
    }
  
    .backimg {
      width: 100%;
      height: 100vh;
    }
    .img-logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      margin-bottom: 50px;
    }
  }
  
}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include AdminLogin($login-main-background,$login-main-input-border-color,$login-main-input-col-md-color-h2,$login-main-content-border-left,$login-main-content-control-label,$login-right-side-col-background,$login-main-button-background);
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include AdminLogin($login-main-background,$login-main-input-border-color,$login-main-input-col-md-color-h2,$login-main-content-border-left,$login-main-content-control-label,$login-right-side-col-background,$login-main-button-background);
}

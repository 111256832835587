// all common imports;

@mixin InlineTableForm(
  $inline-edit-border,
  $inline-edit-background,
  $inline-edit-hover-background,
  $border-color-blue,
  $background-color-summary,
  $font-color-summary
) {
  .inlineTableFormEdit {
    .editIcon {
      display: flex;
      height: 20px;
      width: 20px;
      align-items: center;
      justify-content: center;
      border: 1px solid $inline-edit-border;
      border-radius: 5px;
      transition: 0.2s all;
      font-size: 12px;
      background: $inline-edit-background;
    }
    .editIcon:hover {
      background: $inline-edit-hover-background;
    }
  }

  .fieldMinimumHeight {
    min-height: 48px;
  }

  .fieldMinimumWidth {
    min-width: 140px;
  }

  // table ui design
  .inlineTableForm th[action="true"]:last-child {
    position: sticky;
    right: 0px;
  }

  .inlineTableForm td[action="true"]:last-child {
    position: sticky;
    right: 0px;
    z-index: 10;
  }

  .details {
    width: calc(100% - 30%);
    border-left: 3px solid $border-color-blue !important;
  }

  .details > .table {
    max-width: calc(100% - 20%);
    width: calc(100% - 20%);
    min-width: 600px;
  }

  .inline-table-form-summary-section-bg{
    background-color: $background-color-summary !important;
    color: $font-color-summary !important;
  }

  .inline-table-form-summary-section-bg span, .inline-table-form-summary-section-bg label{
    font-weight: 900;
    color: $font-color-summary !important;
  }

  .details > .table > input, .details_table_input {
    width: 140px;
  }

  @media only screen and (max-width: 768px) {
    .inlineTableForm th[action="true"]:last-child {
      position: initial;
    }
    .inlineTableForm td[action="true"]:last-child {
      position: initial;
    }
  }

}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include InlineTableForm(
    $inline-edit-border,
    $inline-edit-background,
    $inline-edit-hover-background,
    $border-color-blue,
    $blue-gray-400,
    $font-color-white
  );
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include InlineTableForm(
    $inline-edit-border,
    $inline-edit-background,
    $inline-edit-hover-background,
    $white,
    $blue-gray-400,
    $deep-gray
  );
}




// Import your styles at the top level
@import "../../../styles/themes/theme-default.scss";
@import "../../../styles/themes/theme-dark.scss";
@import "../../../styles/theme-color-variables.scss";

// Define your mixin
@mixin InlineTableForm($text-color) {
    .content {
        color: $text-color;
        font-size: 100px;
        font-weight: 600;
    }
}

// Use the mixin for different themes
html.default {
    @include InlineTableForm($gray);
}

html.dark {
    @include InlineTableForm($gray);
}


@mixin ViewPageComponent($white) {
    .toggle-div {
        display: flex;
        position: absolute;
        background-color: $white;
        border-radius: 5px;
        padding: 10px;
        z-index: 1;
        margin-top: 25px;
    }
}

html.default {
    @import "../../../styles/themes/theme-default.scss";
    @import "../../../styles/theme-color-variables.scss";
    @include ViewPageComponent($white);
}

html.dark {
    @import "../../../styles/themes/theme-dark.scss";
    @import "../../../styles/theme-color-variables.scss";
    @include ViewPageComponent($white);
}
.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 95vh;
  width: 100%;
  p {
    text-align: center;
  }
  img {
    max-width: 100%;
    height: 40vh;
    background-color: transparent;
  }
  @media screen and (max-width: 600px) {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  .left_col {
    display: none;
  }

  .right_col {
    margin-left: 0px !important;
    margin-top: -150px !important;
  }

  .content-to-print,
  .content-to-print * {
    display:block;
    visibility: visible;
    z-index: 4560;
  }

}
// all common imports;

@mixin CheckList($border-color, $border-color-dark) {
    .border-dotted{
        border-bottom: 1px dashed $border-color;
    }

    .border-dotted-header{
        border-bottom: 1px solid $border-color-dark;
    }

    .border-left{
        border-left: 3px solid $border-color;
    }

    .toggleFormBtnSize{
        font-size: 32px !important;
    }

    .comment-icon-position{
        position: absolute;
        top:8px;
        right: 10px;
        z-index: 9999;
    }

    .description-label{
        min-width: 50%;
        max-width: 50%;
        width: 50%;
    }

    .comments-box-width{
        width: 200px;
    }

    .comments-box-input{
        padding-right:30px;
    }

    .custom-width{
        width:600px;
    }
}


html.default {
    @import "../../../styles/themes/theme-default.scss";
    @import "../../../styles/theme-color-variables.scss";
    @include CheckList($background-color-blue, $border-color-dark);
}

html.dark {
    @import "../../../styles/themes/theme-dark.scss";
    @import "../../../styles/theme-color-variables.scss";
    @include CheckList($background-color-blue, $border-color-dark);
}
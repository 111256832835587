.option-docstring {
  font-size: 0.7rem;
}

.NodeConfig {
  min-width: 50%;
}

.accordian-header button {
  padding: 5px !important;
}

.modal[role="dialog"] {
  z-index: 1000 !important;
}

.modal-backdrop {
  z-index: 800 !important;
}

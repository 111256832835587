// all common imports;

@mixin DisplayBuilder(
  $accordion-button-background,
  $height-scroll-webkit-scrollbar-track-background,
  $height-scroll-webkit-scrollbar-thumb-background,
  $action-button-container-background,
  $action-button-container-color,
  $kanban-card-display-builder-background,
  $lightRedColor
) {
  .display_component {
    .form-control {
      font-size: 0.8rem;
      font-weight: 500;
    }
  
    .main_display_content {
      h3 {
        font-weight: 400 !important;
        font-size: 16px;
      }
      p {
        font-size: 12px;
        font-weight: 400 !important;
      }
      .accordion_button {
        padding: 0px;
        margin: 0px;
        background: $accordion-button-background;
        font-weight: 500;
        font-size: 18px;
      }
      .component_type_wrapper {
        width: 20%;
      }
  
      .component_type_wrapper_two {
        width: 70%;
      }
  
      .graph_details-inner {
        div {
          margin-right: 10px;
        }
      }
    }
    // scroll bar
    .submenu {
      max-height: 250px;
      overflow-y: auto;
    }
    .height_scroll {
      max-height: 400px !important;
      overflow: auto;
      scroll-behavior: smooth;
      padding-right: 20px;
    }
  }
  
  .height_scroll::-webkit-scrollbar {
    width: 5px;
  }
  
  .height_scroll::-webkit-scrollbar-track {
    background-color: $height-scroll-webkit-scrollbar-track-background;
    border-radius: 100px;
  }
  
  .height_scroll::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: $height-scroll-webkit-scrollbar-thumb-background;
  }
  
  .sort_app .characters li .dropdown-wrapper .dropdown-list li {
    font-size: 1rem !important;
    padding: 0.375rem 0.75rem !important;
    cursor: pointer !important;
    display: flex;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
  }
  
  .cards {
    img {
      width: 50px;
      height: 50px;
      margin-left: 20px;
    }
  }
  
  .actionButtonContainer {
    background-color: $action-button-container-background;
    color: $action-button-container-color;
  }
  
  .accordion_button_toggle {
    border: none !important;
    background: transparent !important;
  }
  
  .display_component .accordion-button::after {
    margin-left: -10px !important;
  }
  
  .kanban-card-display-builder {
    background-color: $kanban-card-display-builder-background;
  }

  .iconTopRight {
    background: $lightRedColor;
    position: absolute;
    top: 0px;
    right: 0px;
    rotate: 45deg;
    translate: 50% -50%;
    height: 55px;
    width: 55px;
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 3px;
    svg {
      rotate: 135deg;
    }
  }
  .actionList {
    transition: 0.2s all;
    opacity: 1;
  }
  .actionButtonContainer:hover .actionList:not(:hover) {
    opacity: 0.2;
  }
}

html.default {
  @import "../../../../styles/themes/theme-default.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include DisplayBuilder(
    $accordion-button-background,
    $height-scroll-webkit-scrollbar-track-background,
    $height-scroll-webkit-scrollbar-thumb-background,
    $action-button-container-background,
    $action-button-container-color,
    $kanban-card-display-builder-background,
    $red-300
  );
}
html.dark {
  @import "../../../../styles/themes/theme-dark.scss";
  @import "../../../../styles/theme-color-variables.scss";
  @include DisplayBuilder(
    $accordion-button-background,
    $height-scroll-webkit-scrollbar-track-background,
    $height-scroll-webkit-scrollbar-thumb-background,
    $action-button-container-background,
    $action-button-container-color,
    $kanban-card-display-builder-background,
    $red-300
  );
}
// all common imports;

@mixin ToggleButton($toggle-switch-background,$toggle-switch-border,$toggle-switch-slider-before-background,$switch-input-checked-slider-background,$switch-input-checked-slider-before-background,$toggle-switch-text) {
  .toggleSwitch{
    .switch {
      position: relative;
      display: inline-block;
      width: 52px;
      height: 26px;
    }
    
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $toggle-switch-background;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border: 1px solid $toggle-switch-border;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 17px;
      width: 17px;
      left: 4px;
      bottom: 4px;
      background-color: $toggle-switch-slider-before-background;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    
    .switch input:checked + .slider {
      background-color: $switch-input-checked-slider-background;
    }
    
    .switch input:focus + .slider {
      box-shadow: 0 0 1px $switch-input-checked-slider-background;
    }
    
    .switch input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
      box-shadow: 2px 1px 5px $switch-input-checked-slider-before-background;
    }
    
    .slider.round {
      border-radius: 34px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }
    
    .toggleSwitchText {
      font-size: 18px;
      color: $toggle-switch-text;
    }
    
    .switch input:disabled + .slider {
      cursor: initial;
    }
    
    .disableSwitch {
      opacity: 0.5;
    }
    
  }
}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include ToggleButton($toggle-switch-background,$toggle-switch-border,$toggle-switch-slider-before-background,$switch-input-checked-slider-background,$switch-input-checked-slider-before-background,$toggle-switch-text);
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include ToggleButton($toggle-switch-background,$toggle-switch-border,$toggle-switch-slider-before-background,$switch-input-checked-slider-background,$switch-input-checked-slider-before-background,$toggle-switch-text);
}
.helpContainer{
    font-size: 1rem
}

.helpButton{
    padding: 12x 20px !important;
}

.list{
    list-style: none;
}
.fa-yelp{
    margin-right: 12px !important
}
.custom-node-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-node{
    border: solid 2px gray;
    border-radius: 4px;
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.custom-node-name {
    margin-bottom: 3px;
}

.port-col {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.port-col-in {
    transform: translate(-100%, 0%);
}

.port-col-out {
    transform: translate(110%, 0%);
}

.triangle-port {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 20px solid rgb(100, 100, 100);
}

.triangle-port:hover{
    border-left-color: mediumpurple;
}

.flow-port-div {
    position: absolute;
    top: -5px;
}

.flow-port-div-in {
    left: -5px;
}

.flow-port-div-out {
    left: 85%;
}

.flow-port {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: purple;
}

.custom-node-icons {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.custom-node-configure {
    font-size: 1.5rem;
    cursor: pointer;
}

.custom-node-tabular {
    cursor: pointer;
}

.custom-node-description {
    margin-top: 5px;
    text-align: center;
    max-width: 80px;
    font-size: 0.5rem;
}

// all common imports;

@mixin SummaryCard($summary-card-value-color,$summary-card-label-color) {
  .summaryCard{
    border: 0px;
    border-radius: 20px;
    font-size:14px;
  }
  
  .summaryCardValueContent{
    justify-content: flex-end;
    color: $summary-card-value-color;
  }
  
  .summaryCardLabelContent{
    font-size: 12px;
    line-height: 2.0em;
    color:$summary-card-label-color;
    font-weight: 600 !important;
  }
  
  .summaryCardHeader {
    position: absolute;
    right: 10px;
    top: -5px;
  }
  
  .summaryContent{
    margin-top:10px;
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include SummaryCard($summary-card-value-color,$summary-card-label-color);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include SummaryCard($summary-card-value-color,$summary-card-label-color);
}
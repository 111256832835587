// all common imports;

@mixin Loader(
  $loader-circle-border-light,
  $loader-circle-border-normal,
  $app-loader-background,
  $app-loader-color
) {
  .wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    margin-top: 10%;
    margin-left: 45%;
  }
  .loader-circle {
    width: 40px;
    height: 40px;
    border: 5px solid;
    border-color: $loader-circle-border-light $loader-circle-border-normal;
    border-radius: 50%;
    animation: spin 2s infinite;
    float: center !important;
    align-items: center;
    text-align: center;
  }

  .loader-circle-sm {
    width: 25px;
    height: 25px;
    border: 3px solid;
    border-color: $loader-circle-border-light $loader-circle-border-normal;
    border-radius: 50%;
    animation: spin 2s infinite;
    float: center !important;
    align-items: center;
    text-align: center;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .appLoader {
    background: $app-loader-background;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 77;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-top: 0px !important;
    border-radius: 20px;
    color: $app-loader-color;
  }
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Loader(
    $loader-circle-border-light,
    $loader-circle-border-normal,
    $app-loader-background,
    $app-loader-color
  );
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Loader(
    $loader-circle-border-light,
    $loader-circle-border-normal,
    $app-loader-background,
    $app-loader-color
  );
}

// all common imports;

@mixin AddUser($dropdown-list-ul-border, $dropdown-list-ul-color, $dropdown-list-li-hover-color, $dropdown-list-li-hover, $dropdown-list-background) {
  
  .dropdown-wrapper {
    display: inline-block;
  }

  .dropdown-list ul {
    margin: 0;
    padding: 0px;
    display: inline-block;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    border: 1px solid $dropdown-list-ul-border;
    border-top: none;
    color: $dropdown-list-ul-color;
    list-style: none;
    min-height: 1.2em;
  }

  .dropdown-list li {
    font-size: 1rem !important;
    padding: 0.375rem 0.75rem !important;
    cursor: pointer !important;
  }

  .dropdown-list li:hover {
    color: $dropdown-list-li-hover-color;
    background: $dropdown-list-li-hover;
  }

  .dropdown-list {
    position: absolute;
    background: $dropdown-list-background;
    z-index: 1;
    width: 100%;
  }

  .toggleEyePosition {
    position: absolute;
    top: 27%;
    right: 3%;
    font-size: 17px;
    cursor: pointer;
  }

  .saved-user-table .table > :not(caption) > * > * {
    padding: 15px 0.5rem !important;
  }

  @media screen and (max-width: 600px) {
    .toggleEyePosition {
      right: 5%;
    }
  }

}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include AddUser($dropdown-list-ul-border, $dropdown-list-ul-color, $dropdown-list-li-hover-color, $dropdown-list-li-hover, $dropdown-list-background);
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include AddUser($dropdown-list-ul-border, $dropdown-list-ul-color, $dropdown-list-li-hover-color, $dropdown-list-li-hover, $dropdown-list-background);
}

// all common imports;

@mixin GoogleMap($product-map-background, $product-map-markers-color, $product-map-marker-model-color,$product-map-marker-model-background, $product-map-marker-model-border, $related-products-border,$related-products-background) {
   
  .map-info-window{
    .marker-info{
      font-weight: bolder;
      padding-top: 2px;
    }
    .info-image{
      width: 100%;
      height: 150px;
      
    }
  }

  .autosearch-searchbox{
    position: relative;
    border: 1px solid $related-products-border;
    border-radius: 5px;
    padding-right: 15px;
  }

  .autosearch-suggestions{
    overflow:hidden;
    text-overflow:ellipsis;
    cursor:pointer;
    border-bottom: 1px solid lightblue;
    background-color: $product-map-background;
    padding: 15px;
    font-size: 13px;
  }
    
    @media screen and (max-width: 600px) {
      .map-view {
        height: 500px !important;
      }
      .responsive-margin {
        margin-top: 20px;
      }
    }
  }
  
  html.default {
    @import "../../../styles/themes/theme-default.scss";
    @import "../../../styles/theme-color-variables.scss";
    @include GoogleMap($product-map-background, $product-map-markers-color, $product-map-marker-model-color,$product-map-marker-model-background, $product-map-marker-model-border, $related-products-border,$related-products-background);
  }
  html.dark {
    @import "../../../styles/themes/theme-dark.scss";
    @import "../../../styles/theme-color-variables.scss";
    @include GoogleMap($product-map-background, $product-map-markers-color, $product-map-marker-model-color,$product-map-marker-model-background, $product-map-marker-model-border, $related-products-border,$related-products-background);
  }
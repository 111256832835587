@mixin flex-helper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox-inline,
.radio-inline {
  margin-right: 15px;
  margin-bottom: 0px;
  span {
    @include flex-helper;
    input {
      margin-right: 2px;
    }
  }
}

.modal-form .spinner-border {
  height: 1rem !important;
  width: 1rem !important;
}

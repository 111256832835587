@mixin FilterComponent(
  $single-filter-background,
  $single-filter-border,
  $clear-filter-btn-hover-background,
  $clear-filter-btn-color,
  $clear-filter-color
) {
  .singleFilterBackground {
    background: $single-filter-background;
  }

  .toggleFilter {
    font-size: 14px;
    .height_scroll {
      max-height: 190px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .add_remove_size {
    font-size: 17px;
  }

  .filterBorder {
    border-top: 1px dotted $single-filter-border !important;
  }

  .clear-filter-btn {
    padding: 5px 9px;
  }

  .clear-filter-btn:hover {
    background-color: $clear-filter-btn-hover-background !important;

    .clear-filter-span {
      color: $clear-filter-btn-color !important;
    }

    .clear-filter-icon {
      color: $clear-filter-btn-color !important;
    }
  }

  .clear-filter-span {
    color: $clear-filter-color !important;
  }

  .clear-filter-icon {
    color: $clear-filter-color !important;
  }

  .filterComponentSpinner .spinner-border {
    height: 1rem !important;
    width: 1rem !important;
  }
  .filterColumnContainer {
    top: 100%;
    width: 150px;
  }
  .isFiltered {
    position: absolute;
    top: 3px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    right: -3px;
  }
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include FilterComponent(
    $single-filter-background,
    $single-filter-border,
    $clear-filter-btn-hover-background,
    $clear-filter-btn-color,
    $clear-filter-color
  );
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include FilterComponent(
    $single-filter-background,
    $single-filter-border,
    $clear-filter-btn-hover-background,
    $clear-filter-btn-color,
    $clear-filter-color
  );
}

// all common imports;

@mixin DatasetStructure($border-color-dark) {
  .data-structure-header {
    border-bottom: 1px dotted $border-color-dark;
  }

  .accordion-body ul{
    padding-left: 0px !important;
  }

  .accordion-body ul li{
    padding: 10px !important;
  }

  .floating-button{
    position: fixed !important;
    bottom: 47px;
    right: 8px;
  }
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include DatasetStructure($border-color-dark);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include DatasetStructure($border-color-dark);
}
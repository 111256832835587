// all common imports;

@mixin SubscriptionList($bell-icon-color,$bell-icon-color-hover) {
   .actionicon{
      font-size: 20px;
  }
  .bell_icon{
     cursor: pointer;
     color: $bell-icon-color;
     font-size: 20px;
  }
  
  .bell_icon:hover{
     color: $bell-icon-color-hover;
    transition: 0.3s ease-in;
  }
}

html.default {
   @import "../../styles/themes/theme-default.scss";
   @import "../../styles/theme-color-variables.scss";
   @include SubscriptionList($bell-icon-color,$bell-icon-color-hover);
 }
 html.dark {
   @import "../../styles/themes/theme-dark.scss";
   @import "../../styles/theme-color-variables.scss";
   @include SubscriptionList($bell-icon-color,$bell-icon-color-hover);
 }
.rootHierarchy {
  flex-grow: 1;
  font-size: 16px;
  .selectedHierarchy {
    background-color: #ededed;
    -webkit-box-shadow: 2px 2px 6px -3px rgba(160, 162, 163, 0.33);
    -moz-box-shadow: 2px 2px 6px -3px rgba(160, 162, 163, 0.33);
    box-shadow: 2px 2px 6px -3px rgba(160, 162, 163, 0.33);
    font-weight: 600;
    cursor: context-menu;
    border: 0.5px solid rgb(215, 213, 213) !important;
  }

  .hierarchyIconColor {
    color: #ffca28;
  }
  .hierarchyEditIconColor {
    color: #c1c1c1;
  }

  .openHierarchyLine {
    position: relative;
    display: flex;
    align-items: center;
  }

  .openHierarchyLine:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 1.5px dashed;
  }

  .addNewHierarchyTextBox {
    outline: none;
    border: 1px solid #ced4da;
  }

  .folderButtons {
    display: none;
  }

  .rowCategory {
    border: 1px solid transparent;
  }

  .rowCategory:hover {
    border: 0.5px solid rgb(215, 213, 213);
  }

  .rowCategory:hover .folderButtons {
    display: initial;
  }
}

.cursorContexMenu {
  cursor: context-menu;
}

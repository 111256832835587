// all common imports;

@mixin ReactUI(
  $app-label-required,
  $reactui-control-label-color,
  $reactui-update-view-button-color,
  $reactui-custom-content-color,
  $reactui-tab-active-background,
  $reactui-tab-active-box-shadow,
  $reactui-tab-active-hover-color,
  $reactui-display-component-ui-background,
  $reactui-custom-comment-input-border,
  $background-color-light,
  $background-color-white,
  $border-color-bottom,
  $gray,
  $black
){
  #root { 
    $reactUiTabActiveBackground : $reactui-tab-active-background;
    $reactUiTabActiveBorderBottomColor : $border-color-bottom;
    $formLabelColor : $gray;
  }
  .react_ui {
    .x_panel {
      margin-top: 4rem;
    }
    fieldset#root {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .form-group.field.field-string,
    .form-group.field.field-object .form-group.field.field-object,
    .form-group.field.field-number,
    .form-group.field.field-array {
      width: calc(33% - 20px);
      margin:0 10px 0 10px;
    }

    @media only screen and (max-width: 480px) {
      .form-group.field.field-string,
      .form-group.field.field-object .form-group.field.field-object,
      .form-group.field.field-number,
      .form-group.field.field-array {
        width: 100% !important;
        margin: 0 20px 20px 0px;
      }
    }

    .form-group.field.field-object #root .hidden {
      display: none;
    }
    .form-group.field.field-string.label > label {
      display: none;
    }

    .form-group.field.field-string.label > div {
      margin-top: 2rem;
    }
    .table_head {
      text-transform: capitalize;
    }
  }
  .flow_btn {
    border: none;
  }
  span.required {
    color: $app-label-required;
  }
  .update-view-button {
    font-size: 16px;
    color: $reactui-update-view-button-color;
    font-weight: bold;
    margin-right: 10px;
    cursor: pointer;
  }

  .react_ui {
    .form-group {
      margin-bottom: 18px !important;
    }
    .help-block {
      font-size: 10px;
      margin-bottom: 0px;
      padding-left: 5px;
      margin-top: 0px;
    }

    #root_trigger_timing__help {
      margin-top: 0px !important;
    }

    .field-radio-group,
    .checkboxes {
      margin-top: 10px !important;
      border-bottom: 1px dotted !important;
    }

    .custom_section {
      margin-top: 10px !important;
    }

    input[type="checkbox"],
    input[type="radio"] {
      height: 22px !important;
      width: 22px !important;
      cursor: pointer !important;
    }

    .ql-toolbar.ql-snow {
      background-color: $background-color-light !important;
      display: flex;
      flex-wrap: nowrap;
      max-height: 40px;
      overflow-x: hidden;
      overflow-y: hidden;
      scrollbar-width: none;
    }

    .ql-editor.ql-blank {
      background-color: $background-color-white !important;
    }

    label.control-label {
      font-size: 12px;
      font-weight: 600;
      color: var(--formLabelColor) !important;
    }

    .app_action_button > button {
      font-size: 12px;
      border: none;
    }
    .app_action_button > button:focus-visible,
    .app_action_button > button:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }

    .font-16 {
      font-size: 16px !important;
    }
    .custom-label {
      font-size: 15px;
      color: $reactui-control-label-color;
      padding: 10px;
    }
    .custom-content {
      font-size: 15px;
      color: $reactui-custom-content-color;
      padding: 10px;
    }
    .custom-table-height {
      height: 150px;
      overflow: scroll;
    }
    .tab_active {
      background-color: var(--reactUiTabActiveBackground);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-weight: bold;
      color: $black;
      box-shadow: $reactui-tab-active-box-shadow 0px 0px 3px 0px;
      border-bottom: 4px solid var(--reactUiTabActiveBorderBottomColor);
    }
  }

  .dummy_image {
    filter: opacity(25%);
  }

  .onLoadingOverflowHidden {
    overflow: hidden;
  }

  .back-button {
    margin-right: 0.75rem !important;
  }

  .submit-button {
    margin-right: 0.75rem !important;
  }

  //for custom comment box
  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    background: $reactui-display-component-ui-background;
  }

  .ql-container.ql-snow {
    height: 80% !important;
  }

  .custom-comment__input {
    padding: 7px !important;
    border-radius: 4px !important;
    outline: none !important;
    border: 1px solid $reactui-custom-comment-input-border !important;
  }

}

html.default {
  @import "../../styles/themes/theme-default.scss";
  @import "../../styles/theme-color-variables.scss";
  @include ReactUI(
    $app-label-required,
    $reactui-control-label-color,
    $reactui-update-view-button-color,
    $reactui-custom-content-color,
    $reactui-tab-active-background,
    $reactui-tab-active-box-shadow,
    $reactui-tab-active-hover-color,
    $reactui-display-component-ui-background,
    $reactui-custom-comment-input-border,
    $background-color-light,
    $background-color-white,
    $blue-500,
    $gray,
    $black
  );
}
html.dark {
  @import "../../styles/themes/theme-dark.scss";
  @import "../../styles/theme-color-variables.scss";
  @include ReactUI(
    $app-label-required,
    $reactui-control-label-color,
    $reactui-update-view-button-color,
    $reactui-custom-content-color,
    $reactui-tab-active-background,
    $reactui-tab-active-box-shadow,
    $reactui-tab-active-hover-color,
    $reactui-display-component-ui-background,
    $reactui-custom-comment-input-border,
    $background-color-light,
    $background-color-white,
    $custom-light-gray,
    $gray,
    $black
  );
}

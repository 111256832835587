/* *********  tables styling  ******************************* */
// all common imports;

@mixin Table($table-background,	$table-column-focus-outline,	$table-thead-border-bottom,	$table-tbody-border-top,	$table-content-color,	$table-th-shadow,	$table-tfoot-input,	$table-td-group-background,	$table-td-group-border-bottom,	$table-td-group-border-top,	$table-div-box-border,	$table-div-box-background,	$table-data-tables-paginate-box-shadow,	$table-sorting-disabled,	$table-input-border,	$table-header-background,	$table-header-color,	$table-icons-color-dark,	$action-icon-trash-hover,	$table-checked-row-td-background,	$table-checked-view-row-td-background,	$table-no-records-background,	$table-no-records-color,	$table-clicked-page-color,	$table-clicked-page-background,	$cursor-pointer-hover-background,	$table-cell-show-data-background,	$table-cell-show-data-border,	$table-cell-show-data-color,	$table-dynamic-pagination-color,	$table-dynamic-pagination-background,	$table-dynamic-pagination-border,	$table-dynamic-pagination-active-color,	$table-dynamic-pagination-active-link-color,	$table-dynamic-pagination-active-link-background,	$table-pagination-info-color,	$table-link-to-page-color,	$table-webkit-scrollbar-track-background,	$table-webkit-scrollbar-track-background-thumb,	$table-webkit-scrollbar-track-background-scrollbar-thumb,	$table-hierarchy-icon-color,	$table-data-loader-background,	$form-background, $white, $gray) {
 
  #root{
    $tableHeaderBackgroundColor : $white;
    $tableHeaderTextColor : $gray;
    $tableRowBackgroundColor : $white;
    $tableRowTextColor : $gray;
    $tableBorderColor : $table-tbody-border-top;
  }
 
  .dataTables_paginate a {
    padding: 6px 9px !important;
    margin: 0px 2px;
  }
  
  .table-hover tbody tr:hover {
    background-color: $table-background !important;
  }
  
  .dataTables_wrapper {
    position: relative;
    clear: both;
    zoom: 1;
  }
  
  .dataTables_paginate {
    float: right;
    text-align: right;
  }
  
  table.dataTable th.focus,
  table.dataTable td.focus {
    outline: 2px solid $table-column-focus-outline !important;
    outline-offset: -1px;
  }
  
  table.display {
    margin: 0 auto;
    clear: both;
    width: 100%;
  }
  
  .set_height {
    min-height: 150px;
  }
  
  table.display thead th {
    padding: 8px 18px 8px 10px;
    border-bottom: 1px solid $table-thead-border-bottom;
    font-weight: bold;
  }
  
  table.display td {
    padding: 3px 10px;
  }
  
  table.display td.center {
    text-align: center;
  }
  
  table.display thead th:active,
  table.display thead td:active {
    outline: none;
  }
  
  .table td, .table tbody td {
    border-top: 1px solid var(--tableBorderColor);
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    padding: 16px;
    margin: 0px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5;
    font-family: "DM Sans", sans-serif;
    color: var(--tableRowTextColor);
    background-color: var(--tableRowBackgroundColor);
  }
  
  .table th, .table th {
    padding: 16px;
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.5;
    font-family: "DM Sans", sans-serif;
    //box-shadow: $table-th-shadow 0px 2px 10px 0px;
    background-color: var(--tableHeaderBackgroundColor);
    color: var(--tableHeaderTextColor);
  }
  
  .table thead th {
    border: 1px solid var(--tableBorderColor);
    border-bottom-width: 0px;
    padding: 16px;
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.5;
    font-family: "DM Sans", sans-serif;
    //box-shadow: $table-th-shadow 0px 2px 10px 0px;
  }
  
  .parent > .x_panel {
    border: none !important;
  }
  
  .clear {
    clear: both;
  }
  
  .dataTables_empty {
    text-align: center;
  }
  
  tfoot input {
    margin: 0.5em 0;
    width: 100%;
    color: $table-tfoot-input;
  }
  
  td.group {
    background-color: $table-td-group-background;
    border-bottom: 2px solid $table-td-group-border-bottom;
    border-top: 2px solid $table-td-group-border-top;
  }
  
  td.details {
    background-color: $table-td-group-background;
    border: 2px solid $table-td-group-border-top;
  }
  
  div.box {
    height: 100px;
    padding: 10px;
    overflow: auto;
    border: 1px solid $table-div-box-border;
    background-color: $table-div-box-background;
  }
  /* *********  /tables styling  ****************************** */
  // data-tables scss
  table.dataTable {
    clear: both;
    margin: 0px !important;
    border-collapse: collapse;
    table-layout: fixed;
  }
  
  .main-table-scroll {
    table-layout: auto !important;
  }
  
  tbody > tr > :nth-child(n) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  table.dataTable td,
  table.dataTable th {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  table.dataTable td.dataTables_empty,
  table.dataTable th.dataTables_empty {
    text-align: center;
  }
  table.dataTable.nowrap th,
  table.dataTable.nowrap td {
    white-space: nowrap;
  }
  div.dataTables_wrapper div.dataTables_filter {
    text-align: right;
  }
  div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
  }
  div.dataTables_wrapper div.dataTables_filter input {
    display: inline-block;
    width: 90%;
    padding: 15px;
    margin-top: 5px;
  }
  div.dataTables_wrapper div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
    padding: 10px;
    background: $table-background;
    margin: 10px 6px;
    border-radius: 50px;
    box-shadow: 0 0px 15px $table-data-tables-paginate-box-shadow;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
  }
  div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
  }
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    text-transform: capitalize;
  }
  table.dataTable thead > tr > th:active,
  table.dataTable thead > tr > td:active {
    outline: none;
  }
  table.dataTable thead .sorting,
  table.dataTable thead .sorting_asc,
  table.dataTable thead .sorting_desc,
  table.dataTable thead .sorting_asc_disabled,
  table.dataTable thead .sorting_desc_disabled {
    position: relative;
  }
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: block;
    font-family: "DM Sans", sans-serif;
    opacity: 0.5;
  }
  table.dataTable thead .sorting:after {
    opacity: 0.2;
    content: "\e150";
  }
  
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    color: $table-sorting-disabled;
  }
  @media screen and (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_filter,
    div.dataTables_wrapper div.dataTables_paginate {
      text-align: center;
    }
  }
  table.dataTable.table-condensed > thead > tr > th {
    padding-right: 20px;
  }
  table.dataTable.table-condensed .sorting:after,
  table.dataTable.table-condensed .sorting_asc:after,
  table.dataTable.table-condensed .sorting_desc:after {
    top: 6px;
    right: 6px;
  }
  table.table-bordered.dataTable th,
  table.table-bordered.dataTable td {
    border-left-width: 0;
  }
  
  table.table-bordered.dataTable td {
    word-break: break-all;
  }
  
  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable td:last-child,
  table.table-bordered.dataTable td:last-child {
    border-right-width: 0;
  }
  table.table-bordered.dataTable tbody th,
  table.table-bordered.dataTable tbody td {
    border-bottom-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div.dataTables_scrollHead table.table-bordered {
    border-bottom-width: 0;
  }
  div.table-responsive > div.dataTables_wrapper > div.row {
    margin: 0;
  }
  div.table-responsive
    > div.dataTables_wrapper
    > div.row
    > div[class^="col-"]:first-child {
    padding-left: 0;
  }
  div.table-responsive
    > div.dataTables_wrapper
    > div.row
    > div[class^="col-"]:last-child {
    padding-right: 0;
  }
  
  .width-auto {
    width: auto;
  }
  
  // custom css
  
  .table-checkbox {
    height: 24px !important;
    display: block;
    width: 24px !important;
  }
  .input-sm {
    height: 20px !important;
    border: 2px solid $table-input-border;
  }
  
  .sorting_asc.width-auto:nth-child(1) {
    width: 3%;
  }
  
  .sorting_asc.width-auto:nth-child(2) {
    width: 7%;
  }
  
  .sorting_asc.width-auto:last-child {
    width: 8%;
  }
  
  .sorting_asc.width-auto:nth-child(1) > .d-help {
    display: none;
  }
  
  .sorting_asc.width-auto {
    vertical-align: middle;
    position: relative !important;
  }
  
  .sorting_asc.width-auto > .d-help {
    position: absolute;
    transform: translate(-50%, -50%);
    right: 1%;
    top: 50%;
  }
  
  .sorting_asc.width-auto > .d-help > i {
    cursor: pointer;
  }
  
  .cur-pointer {
    cursor: pointer;
  }
  
  .sorting_asc.width-auto:last-child {
    vertical-align: middle;
    text-align: center;
  }
  
  .sorting_asc.width-auto:last-child > .d-help {
    display: none;
  }
  
  .head-row {
    background: $table-header-background;
    color: $table-header-color;
  }
  
  .action-icons {
    vertical-align: middle;
  }
  .action-icons i {
    font-size: 20px;
    cursor: pointer;
    color: $table-icons-color-dark;
  }
  
  .action-icons .fa-trash:hover {
    color: $action-icon-trash-hover;
  }
  
  .checkedRow td {
    transition: 0.2s;
    background-color: $table-checked-row-td-background !important;
  }
  
  .checkedViewRow td {
    transition: 0.2s;
    background-color: $table-checked-view-row-td-background !important;
  }
  
  .no_table_records {
    padding: 10px;
    font-size: 14px;
    background: $table-no-records-background;
    margin: 0;
    color: $table-no-records-color;
  }
  
  .clicked-page {
    color: $table-clicked-page-color !important;
    background: $table-clicked-page-background !important;
    font-weight: bold;
    border-radius: 30px;
  }
  
  .showDiv {
    display: block;
  }
  
  .hideDiv {
    display: none;
  }
  
  .cursor-pointer:hover {
    background: $cursor-pointer-hover-background;
  }
  
  .cellShowData {
    display: none;
    position: absolute;
    z-index: 100000;
    background-color: $table-cell-show-data-background;
    border: 0.75px solid $table-cell-show-data-border;
    border-radius: 2px;
    padding: 3px;
    color: $table-cell-show-data-color;
  }
  
  .dynamic_pagination {
    color: $table-dynamic-pagination-color;
    font-size:13px;
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    .pagination {
      color: $table-dynamic-pagination-color;
      font-size:13px;
      padding: 5px;
      background: $table-dynamic-pagination-background;
      margin: 10px 3px;
      border-radius: 50px;
      border: 1px solid $table-dynamic-pagination-border;
      li.active {
        color: $table-dynamic-pagination-active-color !important;
        font-size:13px;
        font-weight: bold;
        border-radius: 30px;
        a {
          color: $table-dynamic-pagination-active-link-color;
          font-size:13px;
          background: $table-dynamic-pagination-active-link-background !important;
        }
      }
      li {
        a {
          font-size:13px;
          padding: 2px 8px !important;
          margin: 0px 2px;
          font-weight: bold;
          border-radius: 30px;
        }
      }
    }
  }
  .dynamic_pagination .pagination .disabled a {
    cursor: no-drop;
  }
  
  #pagination_info {
    font-size: 14px;
    color: $table-pagination-info-color;
    font-weight: 500;
  }
  
  .width-30 {
    width: 30%;
  }
  
  .linkToPage {
    font-size: 24px;
    width: 30px;
    transition: 0.2s;
  }
  
  .linkToPage a:hover {
    color: $table-link-to-page-color !important;
  }
  
  .linkToPageFontSize {
    font-size: 15px;
  }
  
  .linkToPageChart {
    position: absolute;
    top: 27px;
    z-index: 4;
    right: 25px;
  }
  
  .table-responsive::-webkit-scrollbar {
    height: 6px;
    width: 0px;
    transition: 0.3s;
  }
  
  .table-responsive::-webkit-scrollbar-track {
    background-color: $table-webkit-scrollbar-track-background;
    transition: 0.3s;
  }
  
  .table-responsive::-webkit-scrollbar-thumb {
    background-color: $table-webkit-scrollbar-track-background-thumb;
    transition: 0.3s;
  }
  .table-responsive:hover::-webkit-scrollbar-thumb {
    background-color: $table-webkit-scrollbar-track-background-scrollbar-thumb;
  }
  
  .maxDisplayToggle {
    max-height: 600px;
    overflow-y: auto;
  }
  
  .maxDisplayToggle::-webkit-scrollbar-track {
    background-color: $table-webkit-scrollbar-track-background;
    transition: 0.3s;
  }
  
  .maxDisplayToggle::-webkit-scrollbar-thumb {
    background-color: $table-webkit-scrollbar-track-background-thumb;
    transition: 0.3s;
  }
  .maxDisplayToggle:hover::-webkit-scrollbar-thumb {
    background-color: $table-webkit-scrollbar-track-background-scrollbar-thumb;
  }
  
  .maxDisplayToggle::-webkit-scrollbar {
    width: 6px;
    transition: 0.3s;
  }
  
  .maxDisplayToggle::-webkit-scrollbar-track {
    background-color: $table-webkit-scrollbar-track-background;
    transition: 0.3s;
  }
  
  .maxDisplayToggle::-webkit-scrollbar-thumb {
    background-color: $table-webkit-scrollbar-track-background-thumb;
    transition: 0.3s;
  }
  
  @media screen and (max-width: 768px) {
    .maxDisplayToggle {
      max-height: initial;
    }
  }
  
  // hierarchy table design
  .hierarchyParent {
    display: inline-block;
    min-width: 180px;
    width: 100%;
  }
  
  .hierarchyIconColor {
    color: $table-hierarchy-icon-color;
  }
  
  .fileIcon {
    visibility: hidden;
  }
  
  .rootHierarchyTable:hover .fileIcon {
    visibility: visible;
  }
  
  .textOverflow {
    overflow: hidden;
    direction: rtl;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .tableDataLoader,
  .tableNoData {
    //background: $table-data-loader-background;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    z-index: 77;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    overflow: hidden;
    border-top: 0px !important;
  }
  
  .tableNoData {
    bottom: 6px;
    top: 39px;
    left: 1px;
    right: 1px;
  }
  
  .tableDataLoader .subTable {
    height: 50px;
    align-items: center;
    display: flex;
  }
  
  .tableDataLoader .hierarchy {
    height: 40px;
    align-items: center;
    display: flex;
    td {
      border: 0px !important;
      padding: 0px !important;
    }
  }
  
  .tableNoRecord {
    height: 42px;
    border: 0px !important;
  }
  
  .hierarchyForm {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: $form-background;
    z-index: 99;
    inset: 0px 0px 0px 0px;
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Table($table-background,	$table-column-focus-outline,	$table-thead-border-bottom,	$table-tbody-border-top,	$table-content-color,	$table-th-shadow,	$table-tfoot-input,	$table-td-group-background,	$table-td-group-border-bottom,	$table-td-group-border-top,	$table-div-box-border,	$table-div-box-background,	$table-data-tables-paginate-box-shadow,	$table-sorting-disabled,	$table-input-border,	$table-header-background,	$table-header-color,	$table-icons-color-dark,	$action-icon-trash-hover,	$table-checked-row-td-background,	$table-checked-view-row-td-background,	$table-no-records-background,	$table-no-records-color,	$table-clicked-page-color,	$table-clicked-page-background,	$cursor-pointer-hover-background,	$table-cell-show-data-background,	$table-cell-show-data-border,	$table-cell-show-data-color,	$table-dynamic-pagination-color,	$table-dynamic-pagination-background,	$table-dynamic-pagination-border,	$table-dynamic-pagination-active-color,	$table-dynamic-pagination-active-link-color,	$table-dynamic-pagination-active-link-background,	$table-pagination-info-color,	$table-link-to-page-color,	$table-webkit-scrollbar-track-background,	$table-webkit-scrollbar-track-background-thumb,	$table-webkit-scrollbar-track-background-scrollbar-thumb,	$table-hierarchy-icon-color,	$table-data-loader-background,	$form-background, $white, $gray);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Table($table-background,	$table-column-focus-outline,	$table-thead-border-bottom,	$table-tbody-border-top,	$table-content-color,	$table-th-shadow,	$table-tfoot-input,	$table-td-group-background,	$table-td-group-border-bottom,	$table-td-group-border-top,	$table-div-box-border,	$table-div-box-background,	$table-data-tables-paginate-box-shadow,	$table-sorting-disabled,	$table-input-border,	$table-header-background,	$table-header-color,	$table-icons-color-dark,	$action-icon-trash-hover,	$table-checked-row-td-background,	$table-checked-view-row-td-background,	$table-no-records-background,	$table-no-records-color,	$table-clicked-page-color,	$table-clicked-page-background,	$cursor-pointer-hover-background,	$table-cell-show-data-background,	$table-cell-show-data-border,	$table-cell-show-data-color,	$table-dynamic-pagination-color,	$table-dynamic-pagination-background,	$table-dynamic-pagination-border,	$table-dynamic-pagination-active-color,	$table-dynamic-pagination-active-link-color,	$table-dynamic-pagination-active-link-background,	$table-pagination-info-color,	$table-link-to-page-color,	$table-webkit-scrollbar-track-background,	$table-webkit-scrollbar-track-background-thumb,	$table-webkit-scrollbar-track-background-scrollbar-thumb,	$table-hierarchy-icon-color,	$table-data-loader-background,	$form-background, $white, $gray);
}
.customTime {
  .timeFields {
    flex-grow: 1;
  }

  .nowButton {
    overflow: hidden;
    width: 0px;
    transition: 0.4s;
  }
}

.customTime:hover .nowButton {
  width: 72px;
}

.customTime input:focus + .nowButton {
  width: 72px;
}
